import React,{useState,useCallback} from 'react'
import { fabric } from "fabric";
import $ from "jquery";


import { ACTIVE_OBJECT,CANVAS_STATES,CANVAS_STATES2,addStateIndex,removeStateIndex,removeStateIndex2, addStateIndex2 } from "./CanvasHistory.js";
import { getCanvasBySide } from './Helpers.js';

const UndoRedo = (props) => {
    const handleUndo = useCallback(() => {
        let state = addStateIndex() 
        let state2=addStateIndex2()   
        let json = CANVAS_STATES[state];
        let json2 = CANVAS_STATES2[state2];



        let frontCanvas = getCanvasBySide(
          props?.allCanvasList,
          "front"
      );

      let backCanvas=getCanvasBySide(
          props?.allCanvasList,
          "back"
      );

      // console.log(json2,"back")
        frontCanvas.loadFromJSON(json, () => {
       
          if(frontCanvas._objects!==null && frontCanvas._objects!==undefined && frontCanvas?._objects.length>0)
          frontCanvas.setActiveObject(frontCanvas?._objects[frontCanvas?._objects.length-1])

        });

        backCanvas.loadFromJSON(json2, () => {
          if(backCanvas._objects!==null && backCanvas._objects!==undefined && backCanvas?._objects.length>0)
          {
          frontCanvas.discardActiveObject()
          backCanvas.setActiveObject(backCanvas?._objects[backCanvas?._objects.length-1])
          }

       
        });


   
        frontCanvas.requestRenderAll();
        backCanvas.requestRenderAll();
        
    
      }, [props.canvas]);
    
      const handleRedo=useCallback(()=>{
    let state1=removeStateIndex();
    let state2=removeStateIndex2();

    let json=CANVAS_STATES[state1];
    let json2=CANVAS_STATES2[state2];

   

  
    let frontCanvas = getCanvasBySide(
      props?.allCanvasList,
      "front"
  );

  let backCanvas=getCanvasBySide(
      props?.allCanvasList,
      "back"
  );

 

    frontCanvas.loadFromJSON(json, () => {
      if(frontCanvas._objects!==null && frontCanvas._objects!==undefined && frontCanvas?._objects.length>0)
      frontCanvas.setActiveObject(frontCanvas?._objects[frontCanvas?._objects.length-1])
    
    });

    backCanvas.loadFromJSON(json2, () => {
      if(backCanvas._objects!==null && backCanvas._objects!==undefined && backCanvas?._objects.length>0)
      {
      frontCanvas.discardActiveObject()
      backCanvas.setActiveObject(backCanvas?._objects[backCanvas?._objects.length-1])
      }
    });



    frontCanvas.requestRenderAll();
    backCanvas.requestRenderAll()
   props.canvas.requestRenderAll()
      },[props.canvas])

  return (
    <>

{$(window).width()>1100?<span><button id="undo"   className="btn btn-outline-secondary px-2 mx-2 " onClick={handleUndo}>
    <img style={{height:"25px",width:"25px"}} className="" src={require('../images/undo1.png')} alt="" />
    </button>
    <button style={{}} id="redo" className="btn btn-outline-secondary " onClick={handleRedo}>
      <img style={{height:"25px",width:"25px"}} className=" " src={require('../images/redo1.png')} alt="" />
    </button>
    </span>:<span>
    <button style={{height:"25px",width:"25px",padding:"inherit"
        }} id="undo"   className="btn btn-outline-secondary mx-2  " onClick={handleUndo}>
    <img style={{height:"15px",width:"15px"}} className="" src={require('../images/undo1.png')} alt="" />
    </button>
    <button style={{height:"25px",width:"25px",padding:"inherit"}} id="redo" className="btn btn-outline-secondary  " onClick={handleRedo}>
      <img style={{height:"15px",width:"15px"}} className=" " src={require('../images/redo1.png')} alt="" />
     </button></span>}
        
    </>
  )
}

export default UndoRedo