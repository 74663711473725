import React, { Component } from "react";

import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import $ from "jquery";
import { PROPS_TO_INCLUDE_IN_CANVAS } from "./constants/index"
import { ACTIVE_OBJECT,CANVAS_STATES,CANVAS_STATES2,addStateIndex,removeStateIndex,removeStateIndex2, addStateIndex2 } from './components/CanvasHistory';
import jwt_decode from "jwt-decode";
import { fabric } from "fabric";
import { Row, Col, Container, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Collapse } from "reactstrap";
import classnames from 'classnames';
import {getCanvasBySide } from './components/Helpers'




import { Tabs, Tab, TabList } from "react-web-tabs";
// import queryString from 'query-string';
import { productTypes, LOGO_URL } from './constants';
//import { BrowserRouter, Route, Switch } from 'react-router-dom';
import FabricCanvas from "./components/FabricCanvas";
import Toolbar from "./components/Toolbar";
import LeftPanel from "./components/LeftPanel";
import logo from "./images/lamcraft_logo.png";

//import Footer from './components/Footer';
import config from "../config";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { templates, VERSES } from "./constants";
import { getObjectByPropValue } from "./components/Helpers"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ListGroup from "reactstrap/lib/ListGroup";

//import $ from 'jquery';
import "./App.scss";
import "./styles/Navbar.scss";
import "./styles/TabView.scss";
import "./styles/LeftSidePanel.scss";
import "./styles/Footer.scss";
import "./styles/FabricCanvas.scss";
import "./styles/Toolbar.scss";
import "./components/modal.css";
import './styles/Responsivemedia.scss'

import { setcanvasBG } from "./components/Helpers";
import { faHelicopterSymbol } from "@fortawesome/free-solid-svg-icons";

fabric.Canvas.prototype.aspectRation = 1



const mapStateToProps=(props)=>{
    return{
        cal:props.cal
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        increment:()=>dispatch({type:"INCREMENT"}),
        decrement:()=>dispatch({type:"DECREMENT"})
    }
}
class App extends Component {
    constructor(props) {
        super(props);
        this.setMask = React.createRef()
        this.state = {
            allCanvasList: [],
            isMasking: false,
            canvas: null,
            canvasObj: [],
            setImage:null,
            isSnap: false,
            maskTab: false,
            isOverlap: false,
            productType: null,
            bookmarkType: "1",
            bookmarkComment: '2.75" w x 8.5" h (4 on a Page)',
            isGrid: false,
            sidebarWidth: 367,
            canvaswidth: "",
            canvasheight: "",
            //defaultbg: require('./images/main-img.jpg'),
            fontBoldValue: "normal",
            fontStrikeValue: "",
            fontItalicValue: "",
            fontUnderlineValue: "",
            collapse: true,
            gridsize: 30,
            imggallery: [],
            top: "",
            isOpen: false,
            currentBookmark: "",
            templateKey: null,
            activeTab: '',
            toolbarVisible: false,
            toggle:false,
            nameToggle:false,
            editNameToggle:false,
            localKey:null,
            designData:null,
            idData:null,
            projectName:null,
            projectData:null,
            projectType:null,
            type:null,
            editId:null,
            isUsed:false,
            usedWord:null,
            userObject:{},
            bullet:false,
            prevZoom:null,
            prodId:null,
            user:null,
            highlightName:null,
            scaleFont:null,

            
        };
        this.currentBookmark = this.currentBookmark.bind(this);
        this.toggle = this.toggle.bind(this);
        this.setMasking = this.setMasking.bind(this);

    }

handleCallbackResponse(response){

//  console.log("Encoded JWT id Token:"+response.credential);
 var userObject=jwt_decode(response.credential)
 this.setState({userObject:userObject})

}
 
    async componentDidMount() {
        const query = new URLSearchParams(window.location.search);
     
 

       let userId=query.get('u')
       let designID=query.get('designId')
       let tokenUrl=query.get('token');

        fabric.enableGLFiltering = false;

        let apiDynamicUrlAddress = config.default.api.host;
        let non = query.get('non') 
        let api=`https://cnsdrive.com/lamcraft/api/shop/v1/user-profile/?user_id=${userId}&token=${tokenUrl}`
         axios.get(api).then((res)=>{console.log("token res",res)}).catch((err)=>{
            window.location.href=`https://cnsdrive.com/lamcraft/`
            console.log(err,"err")})    
        if(designID){
          this.setState({prodId:designID});
        }
        this.imgGallery();
        this.currentBookmark();



            if(!designID  )
            {
            this.setState({toggle:true})  
            }
       
        
        
            
            (async ()=>{
                let id=query.get('designId')
                if(id){
                let api=`${apiDynamicUrlAddress}/getImage?id=${id}`

             await axios.get(api).then((res)=>{
                let htName=res.data.data[0].designName;
                this.setState({highlightName:htName})

                let response=res.data.data[0].json

                // console.log("Data we got from the server",res.data.data[0],res.data.data[0].zoomlevel)
                // console.log("Type of data we got from the server",typeof(response))
                let parsedResponse =response
            //    this.setState({idData:id})
                this.setState({projectData:res.data.data[0].designName})
                let jsonDataFront=response.front;
                let jsonDataBack=response.back;
        
                //this.setState({idData:res.data.data[0]})
        
            let frontCanvas = getCanvasBySide(
                this.state?.allCanvasList,
                "front"
            );
          
            let backCanvas=getCanvasBySide(
                this.state?.allCanvasList,
                "back"
            );
        
            let json=jsonDataFront;
            let json2=jsonDataBack;
 
        

                const newWidth = frontCanvas.getWidth() 
                const newHeight =frontCanvas.getHeight();

                let scalex = newWidth/json.width ;
                let scaley = newHeight/json.height;
     
            
            frontCanvas.loadFromJSON(json,() => {
         
    if (frontCanvas.backgroundImage) {
                    // Need to scale background images as well
                    var bi = frontCanvas.backgroundImage;
    
                    let biscalex= newWidth/bi.width;
                    let biscaley=newHeight/bi.height
                    bi.scaleX =biscalex; 
                    bi.scaleY =biscaley;
                    
                }
               
                let objects = frontCanvas.getObjects();
                
                for (var j in objects) {
        
              
                    objects[j].set({
                        left:objects[j].left*scalex*res.data.data[0].zoomlevel,
                        top:objects[j].top*scaley*res.data.data[0].zoomlevel,
                        scaleX:objects[j].scaleX*scalex*res.data.data[0].zoomlevel,
                        scaleY:objects[j].scaleY*scaley*res.data.data[0].zoomlevel,
                      
                    })
                    objects[j].setCoords();
    
    
                }
             
                 
    
                if(objects){
      
                for (var i in objects) {
                   
                    
                    objects[i].setCoords();
              
    
                        if(objects[i]?.listType=="bullet" || objects[i].listType=="number"){
                     this.setState({bullet:objects[i]})
                    }   
            }
                        frontCanvas.renderAll();
    
                         frontCanvas.calcOffset();
        }
         
                    frontCanvas.renderAll();
    
                     frontCanvas.calcOffset();
                
            });
    
          
            
      
            
            backCanvas.loadFromJSON(json2,() => {
              
                
                let objects1 = backCanvas.getObjects();
                 if (backCanvas.backgroundImage) {

                    // Need to scale background images as well
                    var bi = backCanvas.backgroundImage;

                    let biscalex= newWidth/bi.width;
                    let biscaley=newHeight/bi.height
                    bi.scaleX =biscalex; 
                    bi.scaleY =biscaley;
                    
                }
    
                for (var k in objects1) {
                        
        
                  objects1[k].set({
                    left:objects1[k].left*scalex*res.data.data[0].zoomlevel,
                    top:objects1[k].top*scaley*res.data.data[0].zoomlevel,
                    scaleX:objects1[k].scaleX*scalex*res.data.data[0].zoomlevel,
                    scaleY:objects1[k].scaleY*scaley*res.data.data[0].zoomlevel,
                  
                      
                  })

                  
                  objects1[k].setCoords();
               
                
                    objects1[k].setCoords();
                }
    
         
                for (var i in objects1) {
                    
                    objects1[i].setCoords();
                    if (objects1[i]?.type === 'image' && objects1[i]?.filters.length) {
                        objects1[i].applyFilters();
                    } 
                   
                        if(objects1[i]?.listType=="bullet" || objects1[i].listType=="number"){
                            this.setState({bullet:objects1[i]})
                           }
                        
                }
             
          
                    backCanvas.renderAll();
                    backCanvas.calcOffset();
    
            });
    
    
    
    

                //    document.getElementById("#my-range").innerHTML=res.data.data[0].zoomlevel;
          
                  frontCanvas.requestRenderAll();
                  backCanvas.requestRenderAll()
                  this.state.canvas.renderAll();
    
            
  
                       }).catch((err)=>{console.log("Error",err)})
            }
            })()
         
            setInterval(() => {
                if(designID )
                {
        
                    let frontCanvas = getCanvasBySide(
                        this.state.allCanvasList,
                        "front"
                    );
            
                    let backCanvas=getCanvasBySide(
                        this.state.allCanvasList,
                        "back"
                    )
        
                    // frontCanvas.backgroundImage.src="hello"
                    // backCanvas.backgroundImage.src="hello"
        
                    let frontJson=frontCanvas.toJSON(PROPS_TO_INCLUDE_IN_CANVAS)
                    let backJson=backCanvas.toJSON(PROPS_TO_INCLUDE_IN_CANVAS)
                  let jsonCanvas={front:frontJson,back:backJson};
                    //   console.log(jsonCanvas,"update") 
                    // console.log("Json data before update api",jsonCanvas)  

                    let api=`${apiDynamicUrlAddress}/updateImage`
                    let zoomlevel2=frontCanvas?.getZoom();
                    
                   
                      

                if(zoomlevel2){
                    axios.post(api,{
                        id:designID,
                        json:jsonCanvas,
                        designName:this.state.projectData,
                        zoomlevel:zoomlevel2 
                     }).then((res)=>{
                     
                        const date = new Date();
                          let autoTime=date.toLocaleString();
                           
                       console.log("Auto Save Complete ")
                       document.getElementById('autoSaveMessage').innerHTML='Autosaved..'+autoTime
                       document.getElementById('autoSaveMessage').style.display = 'block';
                       let apiUrl2=`${apiDynamicUrlAddress}/getImage?userID=${userId}`
                        axios.get(apiUrl2).then((response)=>{
                           this.setState({designData:response.data.data})
                       })
            
                       setTimeout(()=>{
                        document.getElementById('autoSaveMessage').style.display = 'none';

                       },(2000))
        
                    
                     }).catch(function (error) {
                        console.log("update error:",error);
                      })
                    }
                }
        
                  },20000);

            let apiUrl2=`${apiDynamicUrlAddress}/getImage?userID=${userId}`
            await axios.get(apiUrl2).then((response)=>{
                this.setState({designData:response.data.data})
            }).catch((err)=>{
            console.log(err)
            })
    }

  

    componentDidUpdate = async (prevProps, prevState) => {
        const query = new URLSearchParams(window.location.search);
        let non = query.get('non')  
        let userId=query.get('u')
        //console.log("prevSTate",this.state)
        if (prevState.canvas !== this.state.canvas) {
            this.state.canvas.off('selection:created',this.toolbarSelectionCreated)
            this.state.canvas.on('selection:created',this.toolbarSelectionCreated)

            this.state.canvas.off('selection:updated',this.toolbarSelectionUpdated)
            this.state.canvas.on('selection:updated', this.toolbarSelectionUpdated)

            this.state.canvas.off('selection:cleared',this.toolbarSelectionCleared)
            this.state.canvas.on('selection:cleared',this.toolbarSelectionCleared)

           
        }

        if(prevState.usedWord !== this.state.usedWord){
            let apiDynamicUrlAddress = config.default.api.host;

            let apiUrl2=`${apiDynamicUrlAddress}/getImage?userID=${userId}`
          await  axios.get(apiUrl2).then((response)=>{
               this.setState({designData:response.data.data})
           })
    let savedName= await this.state.designData?.filter((item)=>{
      return item.designName==this.state.usedWord
    
    })
    if(savedName?.length>0){
        this.setState({isUsed:true})
      
    }
    else{

        this.setState({projectName:this.state.usedWord})
        this.setState({isUsed:false})
    }
    

        }

        // if(prevState.canvas){
        //      if(prevState.canvas._activeObject)
        //     if(prevState.canvas._activeObject.styles !== this.state.canvas.getActiveObject().styles)
        //     {
        //         this.state.canvas.renderAll()
        //     }
        // }


        
    }
    
    toolbarSelectionUpdated=(e)=>{

        this.setState({ toolbarVisible: true })
        this.state.canvas.requestRenderAll()
    
    }

    toolbarSelectionCreated=(e)=>{

        this.setState({ toolbarVisible: true })
        this.state.canvas.requestRenderAll()
    
    }
  
    toolbarSelectionCleared=(e)=>{

        this.setState({ toolbarVisible: false })
        this.state.canvas.requestRenderAll()

    
    }

    currentBookmark = (currentLocc = null) => {
        // let queryParam = window.location.href.split("/")[3];

        const query = new URLSearchParams(window.location.search);
        let template_key = query.get('t')
        const type = query.get('type')

        // console.log('query', type, template_key)
        let productTypeKeys = Object.keys(productTypes)
        let currentLoc = this.state?.productType ? this.state.productType : type;
        if (!template_key) {
            const default_template = getObjectByPropValue(templates, 'default', true)
            template_key = default_template['title'];
        }
        if (!productTypeKeys.includes(currentLoc)) {
            this.openModal();
        } else {
            const selectedProduct = productTypes[currentLoc]
            this.setState({
                currentBookmark: currentLoc,
                templateKey: template_key
            });
            // console.log('selectedProduct', productTypes, currentLoc, selectedProduct)
            this.setState({
                canvaswidth: selectedProduct['canvaswidth'],
                canvasheight: selectedProduct['canvasheight'],
            });
        }
    };
    setTemplateKey = (key) => {
        this.setState({
            templateKey: key
        });
    }

    setActiveTab = (activeTabKey) => {
        this.setState({
            activeTab: activeTabKey
        });
    }

    setImages=(img)=>{
        this.setState({setImage:img})
    }

    setScaleFontSizes=(size)=>{
      this.setState({scaleFont:size})
    }
    openModal = () => {
        this.setState({
            isOpen: true,
        });
    };
    closeModal = () => {
        this.setState({
            isOpen: false,
        });
    };
    productType = (event) => {
        var prodType = event.target.value;
        if (prodType <= 0) {
            this.setState({
                productType: 0,
                bookmarkComment: "",
            });
        } else {
            prodType = JSON.parse(prodType);
            this.setState({
                bookmarkComment: prodType.bookmarkComment,
                productType: prodType.value
            });
        }
    };
    updateCanvas = (canvas) => {
        this.setState(
            {
                canvas: canvas,
            },
            () => {
                //console.log("APP updateCanvas state:::", this.state);
                //console.log("APP updateCanvas canvas:::", canvas);
            }
        );
    };


    setMasking() {
        document.getElementById("vertical-tab-nine-tab").click()
        this.setState({ isMasking: true })
    }

    setMaskingMobile() {
        document.getElementById("tab6").click()
    }

    addCanvasInCanvasLIst = (item) => {
        this.state.allCanvasList.push(item);
    };
    updateCanvases = (side) => {
        //var arr=[];
        const item = this.state.allCanvasList?.find(
            (item) => item.side === side
        );

        this.updateCanvas(item.canvas);
        // canvases.forEach(element => {

        //     this.state.canvasObj.push(element)
        //     if(element == 'front' ){
        //         this.updateCanvas(this.state.canvasObj[0])
        //     }
        //     if(element == 'back' && element?.getElement().id == "back"){
        //         this.updateCanvas(this.state.canvasObj[1])
        //     }
        // });
    };

    updateState = (stateoptions) => {
        this.setState(stateoptions);
        this.currentBookmark();
        //console.log("stateoptions:::", stateoptions);
        //Optimization. This is not not required here
        //this.state.canvas.setDimensions({width: this.state.canvaswidth, height: this.state.canvasheight}, {cssOnly: true});
        //this.state.canvas.setDimensions({width: this.state.canvaswidth}, {cssOnly: true});
    };

    toggleSidebar = (type) => {
        this.setState({ collapse: type });

        this.setState({
            sidebarWidth: type ? 367 : 0,
        });
    };

    export = () => {
        let currentTime = new Date();
        let month = currentTime.getMonth() + 1;
        let day = currentTime.getDate();
        let year = currentTime.getFullYear();
        let hours = currentTime.getHours();
        let minutes = currentTime.getMinutes();
        let seconds = currentTime.getSeconds();
        let fileName =
            month +
            "" +
            day +
            "" +
            year +
            "" +
            hours +
            "" +
            minutes +
            "" +
            seconds;
        const canvasdata = document.getElementById("front");
        const canvasDataUrl = canvasdata
            .toDataURL()
            .replace(/^data:image\/[^;]*/, "data:application/octet-stream"),
            link = document.createElement("a");
        fileName = fileName + ".png";
        link.setAttribute("href", canvasDataUrl);
        link.setAttribute("crossOrigin", "anonymous");
        link.setAttribute("target", "_blank");
        link.setAttribute("download", fileName);
        if (document.createEvent) {
            let evtObj = document.createEvent("MouseEvents");
            evtObj.initEvent("click", true, true);
            link.dispatchEvent(evtObj);
        } else if (link.click) {
            link.click();
        }
    };

    downloadAsJSON = () => {
        let currentTime = new Date();
        let month = currentTime.getMonth() + 1;
        let day = currentTime.getDate();
        let year = currentTime.getFullYear();
        let hours = currentTime.getHours();
        let minutes = currentTime.getMinutes();
        let seconds = currentTime.getSeconds();
        let fileName =
            month +
            "" +
            day +
            "" +
            year +
            "" +
            hours +
            "" +
            minutes +
            "" +
            seconds;
        let canvasdata = this.state.canvas.toDatalessJSON();
        let string = JSON.stringify(canvasdata);
        let file = new Blob([string], {
            type: "application/json",
        });
        let a = document.createElement("a");
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    setBookmark = () => {
        if (this.state.productType <= 0) {
            this.setState({
                productType: 0,
                bookmarkComment: "",
                canvaswidth: 264,
                canvasheight: 816,
            });
        } else {
            const product = productTypes[this.state.productType]
            this.setState({
                bookmarkComment: product.bookmarkComment,
                canvaswidth: product.canvaswidth,
                canvasheight: product.canvasheight,
            });
            this.closeModal();
        }
    };
    setSnap = () => {
        this.setState({
            isSnap: !this.state.isSnap,
        });
        let offstate = document.querySelectorAll("#snapswitch");
        for (let j = 0; j < offstate.length; j++) {
            offstate[j].checked = this.state.isSnap;
        }
    };

    showhideGrid = () => {
        let isGrid = !this.state.isGrid;
        this.setState({
            isGrid: isGrid,
        });
        if (isGrid) {
            for (let i = 0; i < 650 / this.state.gridsize; i++) {
                this.state.canvas.add(
                    new fabric.Line(
                        [
                            i * this.state.gridsize,
                            0,
                            i * this.state.gridsize,
                            650,
                        ],
                        {
                            stroke: "#ccc",
                            selectable: false,
                        }
                    )
                );
                this.state.canvas.add(
                    new fabric.Line(
                        [
                            0,
                            i * this.state.gridsize,
                            650,
                            i * this.state.gridsize,
                        ],
                        {
                            stroke: "#ccc",
                            selectable: false,
                        }
                    )
                );
            }
        } else {
            this.clearGrid();
        }
        let offstate = document.querySelectorAll("#gridswitch");
        for (let j = 0; j < offstate.length; j++) {
            offstate[j].checked = this.state.isGrid;
        }
        this.state.canvas.renderAll();
    };

    clearGrid = () => {
        let objects = this.state.canvas.getObjects("line");
        for (let i in objects) {
            this.state.canvas.remove(objects[i]);
        }
    };

    setOverlap = () => {
        this.setState({
            isOverlap: !this.state.isOverlap,
        });
        let offoverlap = document.querySelectorAll("#overlapswitch");
        for (let j = 0; j < offoverlap.length; j++) {
            offoverlap[j].checked = this.state.isOverlap;
        }
    };

    startNewProject=(name)=>{

        let apiDynamicUrlAddress = config.default.api.host;

       
            let frontCanvas = getCanvasBySide(
                this.state.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.state.allCanvasList,
                "back"
            )

         

            let frontJson=frontCanvas.toJSON(PROPS_TO_INCLUDE_IN_CANVAS)
            let backJson=backCanvas.toJSON(PROPS_TO_INCLUDE_IN_CANVAS)
            // console.log(frontJson,"before posting")

         

          let jsonCanvas={front:frontJson,back:backJson};
          const query = new URLSearchParams(window.location.search);
          let non = query.get('non')  
          let userId=query.get('u')
          let type=query.get('type')
          let zoomlevel1 = frontCanvas?.getZoom()
               

        //   console.log("Data before posting",jsonCanvas,zoomlevel)
        //     console.log("type of data before posting",typeof(jsonCanvas))

            let api=`${apiDynamicUrlAddress}/storeImage`

            if(zoomlevel1){
          axios.post(api,{
            userId:userId,
            json:jsonCanvas,
            designName:name,
            type:type,
            zoomlevel:zoomlevel1
         }).then((res)=>{

            //    console.log("response",res)
               this.setState({idData:res.data.data?.insertId})
               this.setState({projectData:name})

               let url = new URL(window.location.href);

            url.searchParams.set('designId',res.data.data?.insertId);
            setTimeout(()=>{
                window.location.href = url.href;

            },(300))

    
         }).catch(function (error) {
            console.log("post error",error);
          })
        }
    
    }

    imgGallery = () => {
        const query = new URLSearchParams(window.location.search);
        let non = query.get('non')  
        let userId=query.get('u')
        const headers = {
            "Content-Type": "application/json;charset=UTF-8",
        };

        //const {imggallery } = this.state;
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}/image_gallery?userId=${userId}`;
        axios
            .get(apiUrl, {
                //mode: 'cors',
                headers: headers,
                rejectUnauthorized: true,
            })
            .then((response) => {
                this.setState({
                    imggallery: response.data.data,
                 });
            })
            .catch((err) => { console.log("Img Gallery",err)});
    };

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    maskTabToggle(value) {
        this.setState({ maskTab: value })
    }

    handleClick=(value)=>{
        const query = new URLSearchParams(window.location.search);
        let non = query.get('non')  
        let userId=query.get('u')
        let type=query.get('type')
        this.setState({projectType:value.type})
        var url = new URL(window.location.href);


    url.searchParams.set('type',value.type);

query.set('designID',value.id)
window.location.reload()

url.searchParams.set('designId',value.id);

window.location.href = url.href;

    //  this.setState({type:canvastype})
    }

    handleProjectDel=(item)=>{
        let apiDynamicUrlAddress = config.default.api.host;
        const query = new URLSearchParams(window.location.search);
        let non = query.get('non')  
        let userId=query.get('u')

       let delApi= `${apiDynamicUrlAddress}/deteleImage`
       axios.delete(delApi,{data:{"id":item.id}}).then((response)=>{
             let apiUrl2=`${apiDynamicUrlAddress}/getImage?userID=${userId}`
                axios.get(apiUrl2).then((response)=>{
                   this.setState({designData:response.data.data})
               })
  }).catch((err)=>{console.log("Del Error",err)})
    }

    editProjectName=(name)=>{
        const query = new URLSearchParams(window.location.search);
        let non = query.get('non')  
        let userId=query.get('u')
       
      
           
        let frontCanvas = getCanvasBySide(
            this.state.allCanvasList,
            "front"
        );

        let backCanvas=getCanvasBySide(
            this.state.allCanvasList,
            "back"
        )

     

        let frontJson=frontCanvas.toJSON(PROPS_TO_INCLUDE_IN_CANVAS)
        let backJson=backCanvas.toJSON(PROPS_TO_INCLUDE_IN_CANVAS)
        // console.log(frontJson,"before posting")

        let zoomlevel =frontCanvas?.getZoom()

      let jsonCanvas={front:frontJson,back:backJson};
        
        let apiDynamicUrlAddress = config.default.api.host;
    
        let api=`${apiDynamicUrlAddress}/updateImage`
        axios.post(api,{
            id:this.state.editId,
            designName:name,
         }).then((res)=>{
            this.setState({projectData:name})
            let apiUrl2=`${apiDynamicUrlAddress}/getImage?userID=${userId}`

            axios.get(apiUrl2).then((response)=>{
                this.setState({designData:response.data.data})
            })
         
      
         }).catch((err)=>{
            console.log(err)
         })
        

    }

   

     debounce(func, timeout = 2000) {
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            func.apply(this, args);
          }, timeout);
        };
      }
      
       validateUsedWord= (value)=>{
        this.setState({usedWord:value})

      }

     debouncedLog = this.debounce(this.validateUsedWord,500);

    render() {
        const {
            sidebarWidth,
            collapse,
            imggallery,
            bookmarkComment,
            productType,
            
        } = this.state;
        return (
          
            <>
{/*             
             <OidcProvider configuration={oidcconfig}>
                <OidcSecure> */}
                <div>
            <div>
          
            {this.state.toggle || this.state.nameToggle || this.state.editNameToggle?<div className="modal-backdrop  show" style={{zIndex:1000}}></div>:null}
    <div className={`modal fade ${this.state.nameToggle?"show":null}`} style={{display:this.state.nameToggle?'block':''}} id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Enter Project Name:</h5>
        {/* <button type="button" class="close" onClick={()=>{this.setState({nameToggle:false})}}>
          <span aria-hidden="true">&times;</span>
        </button> */}
      </div>
      <div className="modal-body">
      <form>
      <div className="form-group">
      <input  onChange={(event)=>{ 
    
        this.debouncedLog(event.target.value)
}
    } type="text" className="form-control" id="formGroupExampleInput" />

         { this.state.isUsed ? <div class="alert alert-danger alert-dismissible fade show">
        The design name is already used.
    </div>:null}
      </div>
      </form>
      </div>
      <div className="modal-footer">
        {/* <button   type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
        {this.state.projectName?<span style={{display:this.state.isUsed?"none":"block"}}>
        <button type="button" class="btn btn-primary"  onClick={()=>{
            this.setState({nameToggle:false,toggle:false,editNameToggle:false})
            this.startNewProject(this.state.projectName)
            
            }} data-dismiss="modal">Add</button>
    </span>:null}
    {this.state.prodId==null?<button   type="button" class="btn btn-secondary"  onClick={()=>{
      this.setState({editNameToggle:false,nameToggle:false,toggle:true})
    }
          } >Back</button>:<button data-toggle="modal" data-target="#exampleModal"  type="button" class="btn btn-secondary"  onClick={()=>{
            this.setState({editNameToggle:false,nameToggle:false})
          }
                } >Back</button>}


      </div>
    </div>
  </div>
            </div>
   
            <div class={`modal fade ${this.state.toggle?"show":null} `}
              style={{display:this.state.toggle?'block':''}}
            id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">My Projects</h5>

                  {/* <button onClick={()=>{
                    this.setState({toggle:false})}} id="closeModal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button> */}
                </div>
                <div class="modal-body">
                
             <ul class="list-group" style={{height:"300px",overflow:"scroll",overflowX:"hidden"}}>
               {     this.state.designData?.length>0?
                   this.state.designData?.map((item)=>{
                        return  ( <button class="list-group-item" style={{border:this.state.highlightName===item.designName?"2px solid black":null}}><li style={{fontSize:"15px",listStyleType:"none",textAlign:"center"}} ><Row><Col onClick={()=>{this.handleClick(item)}}>{item.designName}</Col><Col> <span onClick={()=>{
                        this.handleProjectDel(item);
    
                        }}>  <i className="far fa-trash-alt text arrow"/></span>
                        <span type="button" title="Edit Project Name" class="btn btn-primary mx-3" data-dismiss="modal"  onClick={()=>{this.setState({editId:item.id,editNameToggle:true,toggle:false})}} style={{border:"none"}} ><img onClick={()=>{document.getElementById("firstClose").click()}} style={{marginBottom:"6px",height:"10px",width:"12px"}} src={require('./images/pencil.png')} alt="Edit"/></span></Col> </Row></li></button>)
                            })  :<h5>You have no saved projects.</h5>  
                        }
              </ul>
                </div>
                <div class="modal-footer">
                  <button id='firstClose' style={{display:"none"}} type="button" class="btn btn-secondary"   data-dismiss="modal"
                  onClick={()=>{
                    this.setState({toggle:false})}}
                  >Close</button>
                 
                  <button type="button" class="btn btn-primary" aria-label="Close" onClick={()=>{
                    this.setState({nameToggle:true})
                    document.getElementById('firstClose').click()
                  }}>
                  Start New Project
                    </button>

                </div>
              </div>
            </div>
          </div>

          <div class={`modal fade ${this.state.editNameToggle?"show":null} `} style={{display:this.state.editNameToggle?'block':''}} id="exampleModalLong1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle1" aria-hidden="true">
    <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle1">Enter Project Name to update:</h5>
  
      </div>
      <div class="modal-body">
      <form>
      <div class="form-group">
      <input defaultValue="" onChange={(event)=>{
        this.debouncedLog(event.target.value)
               
        }} type="text" className="form-control" id="formGroupExampleInput" />

         { this.state.isUsed? <div class="alert alert-danger alert-dismissible fade show">
         The design name is empty or already used.
    </div>:null}
      </div>
      </form>
      </div>
      <div class="modal-footer">
       {this.state.prodId==null?<button type="button" class="btn btn-secondary"  onClick={()=>{
            this.setState({editNameToggle:false,nameToggle:false,toggle:true})}
          
            } >Back</button>:<button data-toggle="modal" data-target="#exampleModal"  type="button" class="btn btn-secondary"  onClick={()=>{
                this.setState({editNameToggle:false,nameToggle:false})}
              
                } >Back</button>} 
        <span style={{display:this.state.isUsed?"none":"block"}}>
        <button  type="button" class="btn btn-primary"  onClick={()=>{
             this.setState({editNameToggle:false,toggle:false,nameToggle:false})

         if (this.state.isUsed==false) {
            // console.log(this.state.projectName,this.state.editId)
          this.editProjectName(this.state.projectName)
        }
          }} data-dismiss="modal">Done</button>
          </span>
      </div>
    </div>
  </div>
            </div>
      
            </div>

                <Container fluid>
                 
                <Row style={{borderBottom:"1px solid grey"}}>
                
                <button  type="button" class="badge badge-primary mx-1 my-1" data-toggle="modal" data-target="#exampleModal">
          My Projects
            </button>
            {/* <div id="signInDiv">
          </div> */}
          {/* <button className="mx-2 btn btn-primary" onClick={()=>{
            this.setState({userObject:{}})
            window.location.reload()}
            }>
            Sign Out
          </button> */}
            {/* <button type="button"  class="badge badge-secondary my-1" onClick={()=>{localStorage.setItem("userId",null)}}>
                Logout
            </button> */}
            {/* <span className="mx-1">
            <h1>
              Count:{this.props.cal}
            </h1>
          <button className="mx-1" onClick={()=>{this.props.increment()}}>Increment</button>
          <button className="mx-1"  onClick={()=>{this.props.decrement()}}>Decrement</button>
            </span> */}
                 <span id="autoSaveMessage"  style={{display:"none",fontSize:"12px",marginLeft:"auto"}} >Autosaved...</span>

                 </Row>
                    <Row className="main-container">
       
                        <div className="tabpanel">
                            <div className="largedevice">
        
                                <Row>
                                    <Col>
                                        <Navbar color="light" light expand="md" style={{ height: $(window).width()>568 && $(window).width()<1100?"150px":"100px" }}>
                                            <NavbarBrand href={LOGO_URL}>
                                                {/* <a href={LOGO_URL} > */}
                                                <img src={logo} alt="" />
                                                {/* </a> */}
                                            </NavbarBrand>
                                        </Navbar>
                                    </Col>
     
                                </Row>
                            </div>
                            <div className="largedevice">
                                <Tabs
                                    defaultTab="vertical-tab-six"
                                    vertical
                                    className="vertical-tabs "
                                >
                                    <TabList>
                                        <Tab
                                            tabFor="vertical-tab-six"
                                            className="lasttab"
                                            onClick={() => this.toggleSidebar(true)}
                                        >
                                            <div className="edit-box">
                                                <i className="fas fa-palette fa-2x text-muted"></i>
                                                <span className="sidenav">
                                                    TEMPLATE
                                                </span>
                                            </div>
                                        </Tab>
                                        <Tab
                                            tabFor="vertical-tab-five"
                                            className="lasttab"
                                            onClick={() => {this.toggleSidebar(true)
                                            this.setState({isMasking:false})}
                                            }
                                        >
                                            <div className="edit-box" id="ImageTab">
                                                <i className="fas fa-image fa-2x text-muted"></i>
                                                <span className="sidenav">
                                                    IMAGE
                                                </span>
                                            </div>
                                        </Tab>
                                        <Tab

                                            tabFor="vertical-tab-one"
                                            className="lasttab"
                                            onClick={() => this.toggleSidebar(true)}
                                        >
                                            <div className="edit-box">
                                                <i className="fas fa-font fa-2x text-muted"></i>
                                                <span className="sidenav">
                                                    TEXT
                                                </span>
                                            </div>
                                        </Tab>
                                        <Tab
                                            tabFor="vertical-tab-eight"
                                            className="lasttab"
                                            onClick={() => this.toggleSidebar(true)}
                                        >
                                            <div className="edit-box">
                                                <i className="fas fa-font fa-2x text-muted"></i>
                                                <span className="sidenav">
                                                    VERSE
                                                </span>
                                            </div>
                                        </Tab>

                                        {/* <Tab tabFor="vertical-tab-two" className="lasttab" onClick={() => this.toggleSidebar(true)}>
                                <div className="edit-box">
                                    <i className="fas fa-fill fa-2x text-muted"></i>
                                    <span className="sidenav">COLOR</span>
                                </div>
                            </Tab> */}
                                        {/* <Tab tabFor="vertical-tab-three" className="lasttab" onClick={() => this.toggleSidebar(true)}>
                                <div className="edit-box">
                                    <i className="fas fa-images fa-2x text-muted"></i>
                                    <span className="sidenav" >CLIP ARTS</span>
                                </div>
                            </Tab> */}
                                        {/* <Tab tabFor="vertical-tab-four" className="lasttab" onClick={() => this.toggleSidebar(true)}>
                                <div className="edit-box">
                                    <i className="fas fa-shapes fa-2x text-muted"></i>
                                    <span className="sidenav">SHAPES</span>
                                </div>
                            </Tab> */}
                                        <Tab
                                            tabFor="vertical-tab-seven"
                                            className="lasttab"
                                            onClick={() => this.toggleSidebar(true)}
                                        >
                                            <div className="edit-box">
                                                <i className="fas fa-print fa-2x text-muted"></i>
                                                <span className="sidenav">
                                                    PRINT
                                                </span>
                                            </div>
                                        </Tab>
                                        <Tab

                                            id="mask_tab"
                                            tabFor="vertical-tab-nine"
                                            className="lasttab"
                                            style={{ display: "none" }}
                                        >
                                            <div
                                                ref={this.setMask}
                                                onClick={() => this.toggleSidebar(true)}
                                                className=""
                                            >
                                                <i className="fas fa-circle fa-2x text-muted"></i>
                                                <span className="sidenav">
                                                    Mask Image
                                                </span>
                                            </div>
                                        </Tab>
                                        {/* {this.state.isMasking?this.toggleSidebar():null} */}

                                    </TabList>
                                    <div
                                        style={{ width: sidebarWidth }}
                                        className="left-side-panel"
                                    >
                                        {this.state.templateKey && collapse && (
                                            <LeftPanel
                                                key="leftpanel"
                                                allCanvasList={
                                                    this.state.allCanvasList
                                                }
                                                canvas={this.state.canvas}
                                                canvasObj={this.state.canvasObj}
                                                imggallery={imggallery}
                                                imgGallery={this.imgGallery}
                                                maskTabToggle={this.maskTabToggle}
                                                currentBookmark={this.state.currentBookmark}
                                                templateSize={{
                                                    width: this.state.canvaswidth,
                                                    height: this.state.canvasheight
                                                }}
                                                templateKey={this.state.templateKey}
                                                setTemplateKey={this.setTemplateKey}
                                                setImages={this.setImages}
                                                // setScaleFontSizes={this.setScaleFontSizes}
                                                // scaleFont={this.state.scaleFont}
                                            />
                                        )}
                                    </div>
                                    <div
                                        className="btn-toggle"
                                        onClick={() => {
                                            this.toggleSidebar(!this.state.collapse);
                                            this.setState({ isMasking: false })
                                        }
                                        }
                                        style={{ opacity: collapse ? 1 : 0 }}
                                    >
                                        <i className="fas fa-chevron-left arrowimage"></i>
                                    </div>
                                </Tabs>
                            </div>
                        </div>

                        <div className="canvas-panel">

                            <div className="canvas-panel-header-toolbar ">
                                {$(window).width()>568?
                                   <Toolbar
                                   state={this.state}
                                   allCanvasList={this.state.allCanvasList}
                                   updateCanvas={this.updateCanvas}
                                   canvasObj={this.state.canvasObj}
                                   setMasking={this.setMasking}
                                   setImage={this.state.setImage}
                                   bullet={this.state.bullet}
                                //    scaleFont={this.state.scaleFont}

                               />: (this.state.toolbarVisible)?
                               <Toolbar

                               state={this.state}
                               allCanvasList={this.state.allCanvasList}
                               updateCanvas={this.updateCanvas}
                               canvasObj={this.state.canvasObj}
                               setMaskingMobile={this.setMaskingMobile}


                           /> :
                           <div className="mobilePanel">
                           <a className="" style={{marginLeft:"30%"}} href={LOGO_URL}>
                            <img src={logo} alt="" />{" "}
                           </a>
                           </div>}


                            </div>

                            <div className="canvas-panel-canvas-area">
                                {/* Create Canvas after getting the size */}
                                {this.state.canvaswidth !== '' ? (
                                    <FabricCanvas
                                    allCanvasList={this.state.allCanvasList}
                                        state={this.state}
                                        currentBookmark={this.currentBookmark}
                                        updateCanvas={this.updateCanvas}
                                        addCanvasInCanvasLIst={
                                            this.addCanvasInCanvasLIst
                                        }
                                        updateCanvases={this.updateCanvases}
                                        updateState={this.updateState}
                                    />
                                ) : null}
                            </div>
                            {/*<Footer canvas={this.state.canvas}>
                        <ul className="navbar-nav ml-md-auto">
                            <li className="nav-item">
                                <a className="nav-link switch" href="{null}" title="Display Grid">Grid <input type="checkbox" id="gridswitch" />
                                    <label htmlFor="gridswitch" onClick={this.showhideGrid}>Toggle</label>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link switch" href="{null}" title="Snap to Grid">Snap <input type="checkbox" id="snapswitch" />
                                    <label htmlFor="snapswitch" onClick={this.setSnap}>Toggle</label>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link switch" href="{null}" title="Overlap">Overlap <input type="checkbox" id="overlapswitch" />
                                    <label htmlFor="overlapswitch" onClick={this.setOverlap}>Toggle</label>
                                </a>
                            </li>
                        </ul>
                    </Footer>*/}
                        </div>

                    </Row>
                   


                    <div className="mobilenav fixed-bottom ">

                        <Tabs>
                            <div
                                className=" fixed-bottom" style={{ position: "relative", zIndex: "auto" }}
                            >
                                {this.state.templateKey && collapse && (
                                    <LeftPanel
                                        key="leftpanel"
                                        allCanvasList={
                                            this.state.allCanvasList
                                        }
                                        canvas={this.state.canvas}
                                        canvasObj={this.state.canvasObj}
                                        imggallery={imggallery}
                                        imgGallery={this.imgGallery}
                                        currentBookmark={this.state.currentBookmark}
                                        templateSize={{
                                            width: this.state.canvaswidth,
                                            height: this.state.canvasheight
                                        }}
                                        activeTab={this.state.activeTab}
                                        templateKey={this.state.templateKey}
                                        setTemplateKey={this.setTemplateKey}
                                        setActiveTab={this.setActiveTab}
                                        toggle={this.toggle}
                                        setImages={this.setImages}
                                     

                                    />
                                )}
                            </div>
                        </Tabs>
                        <nav
                            className="menu"
                            style={
                                {
                                    border: "none",
                                    display: "flex",
                                    overflowX: "scroll",
                                    listStyle: "none",
                                    height: "100px"
                                }
                            }>
                            <NavItem
                                className={classnames({ selectedColor: this.state.activeTab === '2' })}
                            >
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => {
                                        if (this.state.activeTab !== "2") {
                                            this.toggle('2');
                                        } else {
                                            this.toggle('');
                                        }
                                    }}
                                >
                                    <span className="">
                                        <div className="d-flex justify-content-center">
                                            <i className="fas fa-palette fa-2x  " style={{ color: this.state.activeTab === '2' ? "white" : "grey" }}></i>
                                        </div>
                                        <div className="">TEMPLATES</div>
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({ selectedColor: this.state.activeTab === '3' })}
                            >
                                <NavLink
                                  id="imageTabMobile"
                                    className={classnames({ active: this.state.activeTab === '3' })}
                                    onClick={() => {
                                        if (this.state.activeTab !== "3") {
                                            this.toggle('3');
                                        } else {
                                            this.toggle('');
                                        }

                                        this.setState({isMasking:false})
                                    }}
                                >
                                    <span className="">
                                        <div className="d-flex justify-content-center">
                                            <i className="fas fa-image fa-2x " style={{ color: this.state.activeTab === '3' ? "white" : "grey" }}></i>
                                        </div>
                                        <div className="side-nav">IMAGE</div>
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({ selectedColor: this.state.activeTab === '1' })}

                            >

                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => {
                                        if (this.state.activeTab !== "1") {
                                            this.toggle('1');
                                        }

                                        else {
                                            this.toggle('');
                                        }
                                    }
                                    }
                                >
                                    <span className="">
                                        <div className="d-flex justify-content-center">
                                            <i className="fas fa-font fa-2x px-1" style={{ color: this.state.activeTab === '1' ? "white" : "grey" }}></i>
                                        </div>
                                        <div>
                                            TEXT
                                        </div>
                                    </span>

                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({ selectedColor: this.state.activeTab === '4' })}
                            >
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '4' })}
                                    onClick={() => {
                                        if (this.state.activeTab !== "4") {
                                            this.toggle('4');
                                        }

                                        else {
                                            this.toggle('');
                                        }
                                    }}
                                >
                                    <span className="">
                                        <div className="d-flex justify-content-center">
                                            <i className="fas fa-font fa-2x " style={{ color: this.state.activeTab === '4' ? "white" : "grey" }}></i>
                                        </div>
                                        <div>
                                            VERSE
                                        </div>
                                    </span>

                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({ selectedColor: this.state.activeTab === '5' })}

                            >
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '5' })}
                                    onClick={() => {
                                        if (this.state.activeTab !== "5") {
                                            this.toggle('5');
                                        }

                                        else {
                                            this.toggle('');
                                        }
                                    }}
                                >
                                    <span className="">
                                        <div className="d-flex justify-content-center">
                                            <i className="fas fa-print fa-2x " style={{ color: this.state.activeTab === '5' ? "white" : "grey" }}></i>
                                        </div>
                                        <div className="">
                                            PRINT

                                        </div>
                                    </span>
                                </NavLink>
                            </NavItem >
                            <NavItem style={{ display: "none" }} className={classnames({ selectedColor: this.state.activeTab === '6' })}>
                                <NavLink id="tab6"
                                    className={classnames({ active: this.state.activeTab === '6' })}
                                    onClick={() => {
                                        if (this.state.activeTab !== "6") {
                                            this.toggle('6');
                                        }

                                        else {
                                            this.toggle('');
                                        }
                                    }}
                                >
                                    <span className="">
                                        <div className="d-flex justify-content-center">
                                            <i className="fas fa-print fa-2x " style={{ color: this.state.activeTab === '5' ? "white" : "grey" }}></i>
                                        </div>
                                        <div className="">
                                            Mask IMAGE

                                        </div>
                                    </span>
                                </NavLink>
                            </NavItem>
                            {/* <Tab
                                                    ref={this.setMask}
                                                    id="mask_tab"
                                                    tabFor="vertical-tab-nine"
                                                    className="lasttab"
                                                    onClick={() => this.toggleSidebar(true)}
                                                    style={{display:"none"}}
                                                >
                                                    <div className="edit-box">
                                                        <i className="fas fa-circle fa-2x text-muted"></i>
                                                        <span className="sidenav">
                                                            Mask Image
                                                        </span>
                                                    </div>
                    </Tab> */}
                        </nav>


                    </div>

                </Container>
                <Modal
                    show={this.state.isOpen}
                    onHide={this.closeModal}
                    size="default"
                    id="productTypeModal"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <span style={{ fontSize: "24px" }}>
                                No Product!
                            </span>
                        </Modal.Title>
                        <div
                            className=""
                            style={{ position: "absolute", marginLeft: "60%" }}
                        >
                            &nbsp;
                        </div>{" "}
                        {this.state.productType !== 0 ? (
                            <span
                                title="close"
                                style={{ cursor: "pointer" }}
                                onClick={this.closeModal}
                            >
                                X
                            </span>
                        ) : (
                            ""
                        )}
                    </Modal.Header>
                    <Modal.Body>
                        No product selected, please try again
                        {/* {this.state.productType !== null && this.state.productType <= 0 && (
                            <div className="row">
                                <div className="col-12">
                                    <div
                                        className="alert alert-danger"
                                        style={{ width: "100%" }}
                                    >
                                        <strong className="mr-2">Note:</strong>Please
                                        select Product Type.
                                    </div>

                                </div>
                            </div>

                        )}
                        <div className="row">
                            <div
                                className="row-md-6"
                                style={{ width: "68%", paddingLeft: "3%" }}
                            >
                                <select
                                    className="productType"
                                    onChange={this.productType}
                                    defaultValue={productType}
                                >
                                    <option value="0">
                                        --Select Product Type--
                                    </option>
                                    {Object.values(productTypes).map((productType, offset) => (
                                        <option
                                            value={JSON.stringify(productType)}
                                            key={offset}
                                        >
                                            {productType.name}
                                        </option>
                                    ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div
                            className="row"
                            style={{ width: "68%", paddingLeft: "3%" }}
                        >
                            {bookmarkComment}
                        </div> */}
                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: "center" }}>
                        {/* {productType !== 0 ? (
                            <button
                                type="button"
                                id="producttype"
                                className={`btn  ${!this.state.productType ?  "btn-disabled" : "btn-primary" }`}

                                disabled={!this.state.productType}
                                onClick={this.setBookmark}
                            >
                            <i className="fa fa-plus mr-2"></i>Create
                            </button>
                        ) : (
                            <>
                                <button
                                    type="button"
                                    id="producttype"
                                    className="btn btn-primary"
                                    disabled
                                >
                                    <i className="fa fa-plus"></i>
                                    &nbsp;&nbsp;Create
                                </button>
                            </>
                        )} */}

                    </Modal.Footer>
                </Modal>
                </div>
                {/* </OidcSecure>
           
                </OidcProvider>
         */}
            </>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)



