import { PROPS_TO_INCLUDE_IN_CANVAS } from "../constants/index"
import axios from 'axios'
let CANVAS_STATES = [];
let CANVAS_STATES2=[];

let CANVAS_STATE_INDEX = 0;
let CANVAS_STATE_INDEX2 = 0;

// function debounce(func, timeout = 300) {
//     let timer;
//     return (...args) => {
//       clearTimeout(timer);
//       timer = setTimeout(() => {
//         func.apply(this, args);
//       }, timeout);
//     };
//   }



const addToHistory = async (newCan) => {
  CANVAS_STATES.push(newCan?.toJSON(PROPS_TO_INCLUDE_IN_CANVAS));
  CANVAS_STATE_INDEX = CANVAS_STATES.length - 1;

//   const formData = new FormData();
//   formData.append("json", this);
//   formData.append("designName", this.name);
//   formData.append("userId", 1);        
//   let apiUrl ='url'
//   axios
//       .post(apiUrl, formData)
//       .then((response) => {
//           console.log("Autosave complete")
//       })
//       .catch((err) => {});
};

const addToHistory2 = async (newCan) => {


    CANVAS_STATES2.push(newCan?.toJSON(PROPS_TO_INCLUDE_IN_CANVAS));
  
    CANVAS_STATE_INDEX2 = CANVAS_STATES2.length - 1;
   
  }




    const addStateIndex = ()=>{

        if(CANVAS_STATE_INDEX>=0)
        {
            CANVAS_STATE_INDEX = CANVAS_STATE_INDEX-1
          
        }
       
        return CANVAS_STATE_INDEX
    }

    const addStateIndex2 = ()=>{

        if(CANVAS_STATE_INDEX2>=0)
        {
            CANVAS_STATE_INDEX2 = CANVAS_STATE_INDEX2-1
          
        }
       
        return CANVAS_STATE_INDEX2
    }

const removeStateIndex=()=>{

    if(CANVAS_STATE_INDEX<=CANVAS_STATES.length-1)
    {
        CANVAS_STATE_INDEX = CANVAS_STATE_INDEX+1;
    }
 

    return CANVAS_STATE_INDEX 
}

const removeStateIndex2=()=>{

    if(CANVAS_STATE_INDEX2<=CANVAS_STATES2.length-1)
    {
        CANVAS_STATE_INDEX2 = CANVAS_STATE_INDEX2+1;
    }
 

    return CANVAS_STATE_INDEX2 
}

const resetHistory=()=>{
  while(CANVAS_STATES.length>0) 
  {
         CANVAS_STATES.pop()
  }
}

const removeStates=()=>{
  CANVAS_STATES=CANVAS_STATES.filter((ele,index)=>{
     return index <=CANVAS_STATE_INDEX
    })
}

const removeStates2=()=>{
    CANVAS_STATES2=CANVAS_STATES2.filter((ele,index)=>{
       return index <=CANVAS_STATE_INDEX2
      })
  }

export {CANVAS_STATES,CANVAS_STATE_INDEX,CANVAS_STATES2,CANVAS_STATE_INDEX2, addToHistory,addToHistory2, addStateIndex,addStateIndex2,removeStateIndex,removeStateIndex2,resetHistory,removeStates,removeStates2}