import React, { Component } from 'react';
import { fabric } from 'fabric';
import { SketchPicker } from 'react-color';
import FontPicker from 'font-picker-react';
import Popup from 'reactjs-popup'
import { getOffset, saveCanvasState, selectObject, removeNumbering,getCanvasBySide } from './Helpers'
import { addToHistory,addToHistory2,removeStates,removeStates2 } from "../components/CanvasHistory"

import { DEFAULT_FONT_PROPS } from "../constants";
import { Container, Row } from "reactstrap";
import $ from 'jquery';
//import { text } from '@fortawesome/fontawesome-svg-core';
import config from '../../config';
import axios from 'axios';
// var FontFaceObserver = require('fontfaceobserver');
var WebFont = require('webfontloader');


class Toolbar extends Component {
    state = {
        textColor: '',
        glowColor: '',
        strokeColor: '',
        value: '6',
        opacityval: '1',
        strokeval: '1',
        blurval: '1',
        offsetX: '1',
        offsetY: '1',
        isCropping:false,
        isNewCrop:false,
        cropObject:null,
        currentImage:null,
        activeFontFamily:"Montserrat",
        selectedCanvasObject:null,
        canvasObj:null,
        cropRect:null,
        cropLightRect:null,
        savestateaction: true,
        displayColorPicker: false,
        displaystrokeColorPicker: false,
        displayglowColorPicker: false,
        differentFont:false,
        collapse: false,
        // activeObjectWidth:200,
        activeObjectHeight: 200,
        bulletText:true,
        numberText:true,
        glowcollapse: false,
        open: false,
        backgroundcol: '',
        glowbackgroundcol: '',
        outlinechecked: false,
        glowchecked: false,
        txtlock: false,
        styles: {
            position: 'absolute',
            display: 'none',
        },
        fontFamilyNames:[" ","ABeeZee","Abel","Abril Fatface","Acme","Aerolite","Alegreya","Alegreya Sans","Alfa Slab One","Alex Brush","Amatic SC","Amiri","Anton","Archivo","Archivo Black","Archivo Narrow","Arimo","Arvo","Asap Condensed","Assistant","Barlow","Barlow Condensed","Barlow Semi Condensed","Bebas Neue","Bitter","Bree Serif","Cabin","Cairo","Catamaran","Caveat","Chakra Petch","Changa","Chancery Bold","Chancery Regular","Cinzel","Comfortaa","Cormorant Garamond","Courgette","Covered By Your Grace","Crimson Text","Dancing Script","Didact Gothic","DM Sans","DM Serif Display","Domine","Dosis","EB Garamond","El Messiri","Exo","Exo 2","Fira Sans","Fira Sans Condensed","Fjalla One","Frank Ruhl Libre","Fredoka One","Gothic A1","Heebo","Hind","Hind Madurai","Hind Siliguri","IBM Plex Mono","IBM Plex Sans","IBM Plex Serif","Inconsolata","Indie Flower","Inter","Italianno","Josefin Sans","Jost","Journal","Kalam","Kanit","Karla","Lato","Libre Baskerville","Libre Franklin","Lilita One","Lora","M PLUS 1p","M PLUS Rounded 1c","Manrope","Martel","Maven Pro","Merriweather","Merriweather Sans","Montserrat","Mukta","Mulish","Nanum Gothic","Nanum Myeongjo","Noto Sans","Noto Sans HK","Noto Sans JP","Noto Sans KR","Noto Sans SC","Noto Sans TC","Noto Serif","Noto Serif JP","Nunito,Nunito Sans","Open Sans","Oswald","Overpass","Oxygen","Pacifico","Play","Playfair Display","Poppins","Prompt","PT Sans","PT Sans Caption","PT Sans Narrow","PT Serif","Public Sans","Questrial","Quicksand","Rajdhani","Raleway","Red Hat Display","Roboto","Roboto Condensed","Roboto Mono","Roboto Slab","Rubik","Saira Condensed","Sarabun","Satisfy","Secular One","Shadows Into Light","Signika","Signika Negative","Slabo 27px","Source Code Pro","Source Sans Pro","Source Serif Pro","Space Grotesk","Spectral","Tajawal","Tangerine","Tinos","Titillium Web","Ubuntu","Ubuntu Condensed","Varela Round","Vollkorn","Work Sans","Yanone Kaffeesatz","Yantramanav","Zilla Slab"],
        fontSizes:['','3','4','6','8','9','10','12','14','16','18','21','24','28','32','36','42','48','56','64','72','80','88','96','104','120','144']
    };

  

    constructor(props) {
        super(props);
        this.textcolorRef = React.createRef();
        this.pickerRef = React.createRef();
        this.textstrokecolRef = React.createRef();
        this.textglowcolRef = React.createRef();
    }

  
    componentDidMount() {
        WebFont.load({
            google: {
              families:["ABeeZee","Abel","Abril Fatface","Acme","Alegreya","Alegreya Sans","Alfa Slab One","Alex Brush","Amatic SC","Amiri","Anton","Archivo","Archivo Black","Archivo Narrow","Arimo","Arvo","Asap Condensed","Assistant","Barlow","Barlow Condensed","Barlow Semi Condensed","Bebas Neue","Bitter","Bree Serif","Cabin","Cairo","Catamaran","Caveat","Chakra Petch","Changa","Cinzel","Comfortaa","Cormorant Garamond","Courgette","Covered By Your Grace","Crimson Text","Dancing Script","Didact Gothic","DM Sans","DM Serif Display","Domine","Dosis","EB Garamond","El Messiri","Exo","Exo 2","Fira Sans","Fira Sans Condensed","Fjalla One","Frank Ruhl Libre","Fredoka One","Gothic A1","Heebo","Hind","Hind Madurai","Hind Siliguri","IBM Plex Mono","IBM Plex Sans","IBM Plex Serif","Inconsolata","Indie Flower","Inter","Italianno","Josefin Sans","Jost","Kalam","Kanit","Karla","Lato","Libre Baskerville","Libre Franklin","Lilita One","Lora","M PLUS 1p","M PLUS Rounded 1c","Manrope","Martel","Maven Pro","Merriweather","Merriweather Sans","Montserrat","Mukta","Mulish","Nanum Gothic","Nanum Myeongjo","Noto Sans","Noto Sans HK","Noto Sans JP","Noto Sans KR","Noto Sans SC","Noto Sans TC","Noto Serif","Noto Serif JP","Nunito,Nunito Sans","Open Sans","Oswald","Overpass","Oxygen","Pacifico","Play","Playfair Display","Poppins","Prompt","PT Sans","PT Sans Caption","PT Sans Narrow","PT Serif","Public Sans","Questrial","Quicksand","Rajdhani","Raleway","Red Hat Display","Roboto","Roboto Condensed","Roboto Mono","Roboto Slab","Rubik","Saira Condensed","Sarabun","Satisfy","Secular One","Shadows Into Light","Signika","Signika Negative","Slabo 27px","Source Code Pro","Source Sans Pro","Source Serif Pro","Space Grotesk","Spectral","Tajawal","Tangerine","Tinos","Titillium Web","Ubuntu","Ubuntu Condensed","Varela Round","Vollkorn","Work Sans","Yanone Kaffeesatz","Yantramanav","Zilla Slab"]
            }
          });

        this.setState({
            activeObjectWidth: 200,
            activeObjectHeight: 200,
            canvasObj:this.props.state.canvas?.getActiveObject()
        })

        $(".strokeeff").hide();
        $(".iconbar").hide();
        document.addEventListener("keydown", this._handleKeyDown);

        let canvas = this.props.state.canvas;

    
    }

    _handleKeyDown=(event)=>{
        let DELETE_KEY=46;
        switch( event.keyCode ) {
            case DELETE_KEY:
                this.deleteItem();
                break;
            default: 
                break;
        }
    }

    componentWillReceiveProps = (newprops) => {
        var canvas = this.props.state.canvas;
        if (canvas) {
            var activeObject = canvas.getActiveObject();

            var left = getOffset('front').left;
            var top = getOffset('front').top;
            if (activeObject) {
                this.setState({
                    styles: {
                        top: activeObject.top + top - 50,
                        left: activeObject.left + left + activeObject.width * activeObject.scaleX / 2 + 10,
                        position: 'fixed',
                        display: 'block',
                        zIndex: 1000
                    }
                })
            } else {
                this.setState({
                    styles: {
                        display: 'none',
                    }
                })
            }
        }
        this.selobject();
    }

    selobject = () => {
        var canvas = this.props.state.canvas;
        if (canvas) {
            var activeObject = canvas.getActiveObject();
            if (!activeObject) return false;
            if (activeObject.type === 'text') {

                this.setState({
                    value: activeObject.fontSize,
                    activeFontFamily: activeObject.fontFamily,
                    opacityval: Math.round(activeObject.opacity * 100)
                });


                if (activeObject.shadow) {
                    this.setState({
                        offsetX: activeObject.shadow.offsetX,
                        blurval: activeObject.shadow.blur,
                        offsetY: activeObject.shadow.offsetY,
                        glowColor: activeObject.shadow.color,
                        glowbackgroundcol: activeObject.shadow.color,
                        glowcollapse: true,
                        glowchecked: true
                    });

                } else {

                    this.setState({
                        offsetX: 1,
                        blurval: 1,
                        offsetY: 1,
                        glowColor: '',
                        glowcollapse: false,
                        glowbackgroundcol: '',
                        glowchecked: false
                    });
                }

                if (activeObject.strokeWidth && activeObject.stroke) {
                    this.setState({
                        strokeColor: activeObject.stroke,
                        backgroundcol: activeObject.stroke,
                        strokeval: activeObject.strokeWidth,
                        collapse: true,
                        outlinechecked: true
                    });
                } else {

                    this.setState({
                        strokeColor: '',
                        backgroundcol: '',
                        strokeval: 1,
                        collapse: false,
                        outlinechecked: false
                    });

                }
                //It was changing color of picker arrow
                //$(".textcolpick").css("background", activeObject.fill);

                if (activeObject.fontWeight === 'bold') {
                    $(".tbold").css("opacity", "1");
                } else {
                    $(".tbold").css("opacity", "0.5");
                }
                if (activeObject.fontStyle === 'italic') {
                    $(".titalic").css("opacity", "1");
                } else {
                    $(".titalic").css("opacity", "0.5");
                }
                if (activeObject.underline === 'underline') {
                    $(".tunder").css("opacity", "1");
                } else {
                    $(".tunder").css("opacity", "0.5");
                }
                if (activeObject.textAlign === 'left') {
                    $(".tleft").css("opacity", "1");
                } else {
                    $(".tleft").css("opacity", "0.5");
                }
                if (activeObject.textAlign === 'center') {
                    $(".tcenter").css("opacity", "1");
                } else {
                    $(".tcenter").css("opacity", "0.5");
                }
                if (activeObject.textAlign === 'right') {
                    $(".tright").css("opacity", "1");
                } else {
                    $(".tright").css("opacity", "0.5");
                }
            }

            if (activeObject.type === 'path') {
                this.setState({
                    strokeval: activeObject.strokeWidth,
                    opacityval: Math.round(activeObject.opacity * 100)
                });
            }
            if (activeObject.type === 'image') {
                this.setState({
                    opacityval: Math.round(activeObject.opacity * 100)
                });
            }

            if (activeObject.type === 'group') {
                this.setState({
                    strokeval: activeObject.strokeWidth,
                    opacityval: Math.round(activeObject.opacity * 100)
                });
            }
        }
    }

    setStyle = (styleName, value, o) => {
        if (o.setSelectionStyles && o.isEditing) {
            var style = {};
            style[styleName] = value;
            o.setSelectionStyles(style);
        } else {
            o.set(styleName, value);
        }
        o.setCoords();
    }

    setActiveStyle(styleName, value, object) {
        var canvas = this.props.state.canvas;
        object = object || canvas.getActiveObject();

        if (!object) return;
        if (object.setSelectionStyles && object.isEditing) {
            var style = {};
            style[styleName] = value;
            object.setSelectionStyles(style);
            object.setCoords();
        } else {
            object.set(styleName, value);
        }
        object.setCoords();
        canvas.renderAll();
    }

    setTextFont = (event) => {
        var self = this;
             
    
        self.setActiveStyle('fontFamily',`${event.target.value}`);

        this.setState({
            activeFontFamily: event.target.value
        })


        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );

            setTimeout(()=>{
                addToHistory(frontCanvas)
                addToHistory2(backCanvas)

            },(500))

            // removeStates()
            // removeStates2()
            }
      
    }

    setTextBold = () => {
        var fontBoldValue = (this.props.state.fontBoldValue === "normal") ? "bold" : "normal";
        this.setActiveStyle('fontWeight', fontBoldValue);
        this.props.state.fontBoldValue = fontBoldValue;
        var obj=this.props.state.canvas.getActiveObject()
        this.setState({canvasObj:obj})

        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }
    }

    setTextItalic = () => {
        var fontItalicValue = (this.props.state.fontItalicValue === "normal") ? "italic" : "normal";
        
        this.setActiveStyle('fontStyle', fontItalicValue);
        this.props.state.fontItalicValue = fontItalicValue;
        var obj=this.props.state.canvas.getActiveObject()

        this.setState({canvasObj:obj})

        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }
    }

    setTextUnderline = () => {
        var fontUnderlineValue = !this.props.state.fontUnderlineValue ? "underline" : false;
        this.setActiveStyle('underline', fontUnderlineValue);
        this.props.state.fontUnderlineValue = fontUnderlineValue;
        var obj=this.props.state.canvas.getActiveObject()
        this.setState({canvasObj:obj})

        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }
    }
    setTextStrike = () => {
        var fontStrikeValue = !this.props.state.fontStrikeValue ? "linethrough" : false;
        this.setActiveStyle('linethrough', fontStrikeValue);
        this.props.state.fontStrikeValue = fontStrikeValue;
        var obj=this.props.state.canvas.getActiveObject()
        this.setState({canvasObj:obj})

        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }

    }


    setBulletText=(bulletObj)=>{
        // function s(thing) {
        //     return JSON.stringify(thing, null, 2);
        // }
         this.setState({bulletText:!this.state.bulletText,numberText:true})
        var canvas = this.props.state.canvas;
        var activeObject=canvas.getActiveObject()
        var obj =bulletObj.listType==="bullet"?bulletObj:activeObject;
        if(this.state.bulletText===true){
        const fontWeight = obj?.fontWeight ? obj?.fontWeight : 'normal';
        const fontStyle = obj?.fontStyle ? obj?.fontStyle : '';
        const underline = obj?.underline ? obj?.underline : '';
    obj.set( {
    name: "list",
    fontStyle:fontStyle ,
    fontSize: obj.fontSize,
    fontWeight:fontWeight,
    objectCaching: false,
    isWrapping: false,
    listType: "bullet",
    listBullet: "●",
    listCounter: 0,
    listNull:""
})

const renderTextLine = function (method, ctx, line, left, top, lineIndex) {
    const style0 = this.getCompleteStyleDeclaration(lineIndex, 0),
        bullet = this.listType === "bullet"
                ? [this.listBullet]
                :(this.listType==="number"?[this.listCounter + 1 + "."]:[this.listNull]) ,
        bulletLeft = left - style0.fontSize - 2;
    if (line.length) {
        if (!this.isWrapping) {
            // render the bullet
            this._renderChars(method, ctx, bullet, bulletLeft, top, lineIndex);
            this.isWrapping = !this.isEndOfWrapping(lineIndex);
            if (!this.isWrapping) this.listCounter++;
        } else if (this.isEndOfWrapping(lineIndex)) {
            this.isWrapping = false;
            this.listCounter++;
        }
    }
    if (lineIndex === this.textLines.length - 1) {
        this.isWrapping = false;
        this.listCounter = 0;
    }
    // render the text line
    this._renderChars(method, ctx, line, left, top, lineIndex);
};
obj._renderTextLine = renderTextLine;
canvas.renderAll()
if(canvas){
    let frontCanvas = getCanvasBySide(
        this.props.allCanvasList,
        "front"
    );

    let backCanvas=getCanvasBySide(
        this.props.allCanvasList,
        "back"
    );


    addToHistory(frontCanvas)

    addToHistory2(backCanvas)
  
    

    // removeStates()
    // removeStates2()
    }
        }
        else if(this.state.bulletText===false){
            obj.set( {
                name: "list",
                objectCaching: false,
                isWrapping: false,
                listType:null,
                listBullet:null,
                listCounter: 0,
            })
            canvas.renderAll()

            if(canvas){
                let frontCanvas = getCanvasBySide(
                    this.props.allCanvasList,
                    "front"
                );
        
                let backCanvas=getCanvasBySide(
                    this.props.allCanvasList,
                    "back"
                );

                addToHistory(frontCanvas)
                addToHistory2(backCanvas)
                // removeStates()
                // removeStates2()
                }
        }
        

    }

    setNumberText=(bulletObj)=>{
        // function s(thing) {
        //     return JSON.stringify(thing, null, 2);
        // }
         this.setState({numberText:!this.state.numberText,bulletText:true})
        var canvas = this.props.state.canvas;
        var activeObject=canvas.getActiveObject()
        var obj =bulletObj.listType==="number"?bulletObj:activeObject;
        if(this.state.numberText===true){
        const fontWeight = obj?.fontWeight ? obj.fontWeight : 'normal';
        const fontStyle = obj?.fontStyle ? obj.fontStyle : '';
        const underline = obj?.underline ? obj.underline : '';
    obj.set( {
    name: "list",
    fontStyle:fontStyle ,
    fontSize: obj.fontSize,
    fontWeight:fontWeight,
    objectCaching: false,
    isWrapping: false,
    listType: "number",
    listBullet: "●",
    listCounter: 0,
    listNull:""
})

const renderTextLine = function (method, ctx, line, left, top, lineIndex) {
    const style0 = this.getCompleteStyleDeclaration(lineIndex, 0),
        bullet = this.listType === "bullet"
                ? [this.listBullet]
                :(this.listType==="number"?[this.listCounter + 1 + "."]:[this.listNull]) ,
        bulletLeft = left - style0.fontSize - 2;
    if (line.length) {
        if (!this.isWrapping) {
            // render the bullet
            this._renderChars(method, ctx, bullet, bulletLeft, top, lineIndex);
            this.isWrapping = !this.isEndOfWrapping(lineIndex);
            if (!this.isWrapping) this.listCounter++;
        } else if (this.isEndOfWrapping(lineIndex)) {
            this.isWrapping = false;
            this.listCounter++;
        }
    }
    if (lineIndex === this.textLines.length - 1) {
        this.isWrapping = false;
        this.listCounter = 0;
    }
    // render the text line
    this._renderChars(method, ctx, line, left, top, lineIndex);
};
obj._renderTextLine = renderTextLine;
canvas.renderAll()
if(canvas){
    let frontCanvas = getCanvasBySide(
        this.props.allCanvasList,
        "front"
    );

    let backCanvas=getCanvasBySide(
        this.props.allCanvasList,
        "back"
    );
    addToHistory(frontCanvas)
    addToHistory2(backCanvas)

    // removeStates()
    // removeStates2()
    }
        }
        else if(this.state.numberText===false){
            obj.set( {
                name: "list",
                objectCaching: false,
                isWrapping: false,
                listType:null,
                listBullet:null,
                listCounter: 0,
            })
            canvas.renderAll()
            if(canvas){
                let frontCanvas = getCanvasBySide(
                    this.props.allCanvasList,
                    "front"
                );
        
                let backCanvas=getCanvasBySide(
                    this.props.allCanvasList,
                    "back"
                );
                addToHistory(frontCanvas)
                addToHistory2(backCanvas)
                // removeStates()
                // removeStates2()
                }
        }

   
    }



    setActiveProp = (name, value) => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (!activeObject) return;
        if (activeObject.type === 'activeSelection') {
            activeObject.forEachObject(function (object) {
                object.set(name, value).setCoords();
            });
        } else if (activeObject) {
            activeObject.set(name, value).setCoords();
        }
        canvas.renderAll();
        saveCanvasState(canvas);
    }

    alignObjectLeft = (value) => {
        this.setActiveProp('textAlign', 'left');
        var obj=this.props.state.canvas.getActiveObject()

        this.setState({canvasObj:obj})

        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }
    }

    alignObjectCenter = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        // activeObject.set({
        //     left: (canvas.width / 2) - ((activeObject.width * activeObject.scaleX) / 2)
        // });
        // activeObject.setCoords();
        this.setActiveProp('textAlign', 'center');
        var obj=this.props.state.canvas.getActiveObject()

        this.setState({canvasObj:obj})
        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }
    }

    alignObjectRight = () => {
        this.setActiveProp('textAlign', 'right');
        var obj=this.props.state.canvas.getActiveObject()

        this.setState({canvasObj:obj})
        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }
    }

    clearCanvas = () => {

        var canvas = this.props.state.canvas;
        canvas.clear();
    }

    // deleteItem = () => {
    //     var canvas = this.props.state.canvas;
    //      let apiDynamicUrlAddress = config.default.api.host;
    //     var activeObject = canvas.getActiveObject();
    //     var id=activeObject.objId
    //     if (activeObject.type === 'activeSelection') {
    //         activeObject.forEachObject(function (object) {
    //             canvas.remove(object);
    //         });
    //     } else {
    //         canvas.remove(activeObject);

    //     let apiUrl = `${apiDynamicUrlAddress}/deleteItem?id=${id}`
    //     axios.get(apiUrl, {
    //         crossDomain: true,
    //         enablePreflight: false
    //     }).then((response) => {
    //         window.location.reload();

    //     })
    //         .catch((err) => {

    //         });
    //     }
    // }
    deleteItem = () => {
        var canvas = this.props.state.canvas;
        var apiDynamicUrlAddress = config.default.api.host;
        var activeObject = canvas.getActiveObject();
        var id = activeObject.objId
        if (!activeObject) return;
        if (activeObject.type === 'activeSelection') {
            activeObject.forEachObject(function (object) {
                canvas.remove(object);
            });
        } else {
            if (id === undefined)
                canvas.remove(activeObject);
            else {
                let apiUrl = `${apiDynamicUrlAddress}/deleteItem?id=${id}`
                axios.get(apiUrl, {
                    crossDomain: true,
                    enablePreflight: false
                }).then((response) => {
                    if (response.data.success === 1) {
                        window.location.reload();
                    }
                    //canvas.remove(activeObject);
                    //window.location.reload();

                })
                    .catch((err) => {

                    });
            }

        }

        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }
    }

    setColor = (color) => {

        var canvas = this.props.state.canvas;
        var activeObject=canvas.getActiveObject();
        if (activeObject.type === 'textbox') {
            // activeObject.set('fill', color.hex)
            if (activeObject.isEditing && activeObject.selectionEnd - activeObject.selectionStart > 1) {
                activeObject.setSelectionStyles({ 'fill': color.hex })
            } else {
                activeObject.set({ 'fill': color.hex })
            }
            canvas.renderAll();
        }
        activeObject.setCoords()
        this.changeObjectColor(color.hex);
        this.setState({ textColor: color.hex });
        if(this.textcolorRef.current)
        this.textcolorRef.current.style.background = color.hex;
        // this.selobject();
        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }

    };

    pickerOpen = () => {
        this.setState({
            displayColorPicker: !this.state.displayColorPicker
        })
    };

    rotate = () => {
        var canvas = this.props.state.canvas;

        if (canvas._activeObject) {
            var curAngle = canvas._activeObject.angle;
            canvas._activeObject.rotate(curAngle + 90);
            canvas.renderAll();

            if(this.props.state.canvas){
                let frontCanvas = getCanvasBySide(
                    this.props.allCanvasList,
                    "front"
                );
        
                let backCanvas=getCanvasBySide(
                    this.props.allCanvasList,
                    "back"
                );
                addToHistory(frontCanvas)
                addToHistory2(backCanvas)
                // removeStates()
                // removeStates2()
                }
        }
    };
    componentDidUpdate(prevProps){
        //console.log('re-ren')

        var canvas=this.props.state.canvas;
        if(this.props.state.bullet !==prevProps.bullet)
        {
          if(this.props.state.bullet.listType==="bullet"){
            this.setState({bulletText:true})
            this.setBulletText(this.props.state.bullet)
          }
          else if(this.props.state.bullet.listType==="number"){
            this.setState({numberText:true})

            this.setNumberText(this.props.state.bullet)
          }
        }
        // if(this.props.state.scaleFont!==prevProps.state.scaleFont){
        //     let find=[];
        //     console.log(this.props.state.scaleFont,"here")
        //     find=this.state.fontSizes.filter((item)=>{
        //     if(item===this.props.scaleFont){
        //         return item
        //     }
        //     else
        //     {
        //         return false
        //     }
        //     })  
        //     console.log(find,"find")
        //     if(find.length===0){
        //         console.log("Hello")
        //       this.setState({fontSizes:[...this.state.fontSizes,this.props.state.scaleFont]})
        //     }      
        // }

        // canvas.on('selection:clear',  ()=> {
        //     this.setState({isMasking:false})
        //     });
        if(canvas)

        {
        
            canvas.requestRenderAll()
        
            canvas.off('object:created',this.objectCreated)
        
            canvas.on('object:created',this.objectCreated)
        
            canvas.off('object:modified',this.objectModified)
        
            canvas.on('object:modified',this.objectModified)
        
            canvas.off('selection:created',this.selectionCreated)
        
            canvas.on('selection:created',this.selectionCreated)
        
            canvas.off('selection:updated',this.selectionUpdated)
        
            canvas.on('selection:updated', this.selectionUpdated)
        
            canvas.off('selection:clear',this.selectionCleared)
            
            canvas.on('selection:clear',this.selectionCleared)
        
            canvas.off('mouse:up',this.mouseMovingUp)
        
            canvas.on('mouse:up',this.mouseMovingUp)

        //     canvas.off('object:scaling',this.objectScaled)

        //   canvas.on('object:scaling',this.objectScaled)
        
                
        
            canvas.off('after:render',this.afterRender)
        
            canvas.on('after:render',this.afterRender)
        
              }          
                                   
        }

    eventObjectModified(object) {
        var canvas=this.props.state.canvas;

        var currentObject=this.state.currentImage;
        var selectedCanvas=this.props.state.canvas;
        var selectedCanvasObject=canvas.getObjects().find((item)=>{return item.name==="selectionRect"});

        if (selectedCanvasObject) {
            /* Check the crop object do not cross the image */
            if (selectedCanvasObject.left < currentObject.left) {
                selectedCanvasObject.left = currentObject.left
                selectedCanvasObject.setCoords()
                if (
                    selectedCanvasObject.left + selectedCanvasObject.getScaledWidth() >
                    currentObject.left + currentObject.getScaledWidth()
                ) { 
                    selectedCanvasObject.width = currentObject.getScaledWidth()
                    selectedCanvasObject.scaleX = 1
                    selectedCanvasObject.setCoords()
                }
            }
            if (selectedCanvasObject.top < currentObject.top) {
                selectedCanvasObject.top = currentObject.top
                selectedCanvasObject.setCoords()
                if (
                    selectedCanvasObject.top + selectedCanvasObject.getScaledHeight() >
                    currentObject.top + currentObject.getScaledHeight()
                ) {
                    selectedCanvasObject.height = currentObject.getScaledHeight()
                    selectedCanvasObject.scaleY = 1
                    selectedCanvasObject.setCoords()
                }
            }
            if (
                selectedCanvasObject.left >
                currentObject.left +
                    (currentObject.getScaledWidth() - selectedCanvasObject.getScaledWidth())
            ) {
                if (selectedCanvasObject.getScaledWidth() > currentObject.getScaledWidth()) {
                    selectedCanvasObject.left = currentObject.left
                    selectedCanvasObject.width = currentObject.getScaledWidth()
                    selectedCanvasObject.scaleX = 1
                } else {
                    selectedCanvasObject.left =
                        currentObject.left +
                        (currentObject.getScaledWidth() -
                        selectedCanvasObject.getScaledWidth())
                }
                selectedCanvasObject.setCoords()
            }
            if (
                selectedCanvasObject.top >
                currentObject.top +
                    (currentObject.getScaledHeight() - selectedCanvasObject.getScaledHeight())
            ) {
                if (
                    selectedCanvasObject.getScaledHeight() > currentObject.getScaledHeight()
                ) {
                    selectedCanvasObject.top = currentObject.top
                    selectedCanvasObject.height = currentObject.getScaledHeight()
                    selectedCanvasObject.scaleY = 1
                } else {
                    selectedCanvasObject.top =
                        currentObject.top +
                        (currentObject.getScaledHeight() -
                        selectedCanvasObject.getScaledHeight())
                }
                selectedCanvasObject.setCoords()
            }
        }
    }

    debounce(func, timeout = 2000) {
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            func.apply(this, args);
          }, timeout);
        };
      }
      
       setScaledFontSize= (size)=>{
            let arr=this.state.fontSizes;
        this.setState({fontSizes:[...arr,size.toFixed(2)],value:size.toFixed(2)});

      }


    debouncedLog = this.debounce(this.setScaledFontSize,500);

    objectScaled=()=>{
        let canvas=this.props.state.canvas
        let textObject=canvas?.getActiveObject()
        if(textObject.type==="textbox"){

        let scaleX = parseFloat(textObject.scaleX);
        let scaleY = parseFloat(textObject.scaleY);
        let averageScale;
         console.log("scaleX",scaleX)
        if(scaleX===scaleY){
        averageScale=scaleX;
        }
        console.log("Average",averageScale)
        let currentFontSize = parseFloat(textObject.fontSize);
        let scaledFontSize = parseFloat(currentFontSize) * averageScale.toFixed(2);
        textObject.set({fontSize:scaledFontSize})

        if(scaledFontSize>0){
        this.debouncedLog(scaledFontSize)
        }
        }

    }


    selectionCreated=(e)=>{
        let canvas=this.props.state.canvas
        let object=canvas?.getActiveObject()
        console.log("object",object)

        this.setState({canvasObj:canvas.getActiveObject()})
        // console.log("object",canvas.getActiveObject())
        this.setState({
            value: canvas.getActiveObject().fontSize})

                if (canvas?.getActiveObject().get('type') == 'textbox')
                {
                
                this.setState({ textColor:!canvas.isEditing? canvas?.getActiveObject().fill:null });
                this.setState({activeFontFamily:object.fontFamily})

                this.setState({value:object.fontSize})
                }
               
    
    //     // let object1=canvas.getActiveObject();
    //     // let array=object1?.styles.flat()
    //     // let finalArray=array-object1.textLines;
    //     //  this.setState({activeFontFamily:finalArray[object1.selectionEnd.fontFamily]})
    //     // this.setState({canvasObj:canvas.getActiveObject()})

        if(this.textcolorRef.current)
        this.textcolorRef.current.style.background = canvas?.getActiveObject().fill
    }

    selectionUpdated=(e)=>{
        let canvas=this.props.state.canvas
                
        let fill=canvas?.getActiveObject().fill;

        var object=canvas?.getActiveObject();

        // console.log("object",object)
   


if (canvas?.getActiveObject().get('type') == 'textbox')
{


this.setState({ textColor:!canvas.isEditing? canvas?.getActiveObject().fill:this.state.textColor});
this.textcolorRef.current.style.background =!canvas.isEditing? canvas?.getActiveObject().fill:this.state.textColor

this.setState({
value:object.fontSize,
activeFontFamily:object.fontFamily,
})

}
     //    console.log("obj",this.state.canvasObj)


         if(this.state.currentImage!==object && object.name!=="selectionRect")
         {
         this.setState({isCropping:false})
         let obj=canvas.getObjects().find((item)=>{return item.name==="selectionRect"})
         canvas.remove(obj)  
     }

    
        if(this.textcolorRef.current && !canvas.isEditing)
        {
        this.textcolorRef.current.style.background = canvas.getActiveObject().fill
        }
        else{
            this.textcolorRef.current.style.background = this.state.textColor

        }

  
    }

    selectionCleared=()=>{
        let canvas=this.props.state.canvas
        let object=canvas?.getActiveObject()
        this.setState({canvasObj:canvas.getActiveObject()}) 
        if (canvas?.getActiveObject().get('type') == 'textbox')
{

this.setState({ textColor: canvas?.getActiveObject().fill });
this.setState({
value:object.fontSize,
activeFontFamily:object.fontFamily,
})

}                 

    }

    objectModified=(e)=>{
        let canvas=this.props.state.canvas

        this.eventObjectModified(e)
        this.setState({canvasObj:canvas.getActiveObject()})

        let frontCanvas = getCanvasBySide(
            this.props.allCanvasList,
            "front"
        );

        let backCanvas=getCanvasBySide(
            this.props.allCanvasList,
            "back"
        );

        setTimeout(()=>{
            if(this.textcolorRef.current)
            this.textcolorRef.current.style.background = canvas.getActiveObject().fill
    
        },(10))
    
        addToHistory(frontCanvas)
        addToHistory2(backCanvas)
        
    }

    objectCreated=(e)=>{
        let canvas=this.props.state.canvas


        this.eventObjectModified(e)
        this.setState({canvasObj:canvas.getActiveObject()})

        let frontCanvas = getCanvasBySide(
            this.props.allCanvasList,
            "front"
        );

        let backCanvas=getCanvasBySide(
            this.props.allCanvasList,
            "back"
        );
        removeStates()
        removeStates2()
        addToHistory(frontCanvas)
        addToHistory2(backCanvas)
    }

   


        mouseMovingUp=(event)=>{
            let canvas=this.props.state.canvas
            let object=canvas.getActiveObject();


            if(object?.type==="textbox"){

                if(object.isEditing){
                    let array=[]
         
        
                    let index=object?.selectionEnd-1
         
                      for(let i=0;i<parseInt(object?._textLines.length);i++){
                          
                              array.push(parseInt(object._textLines[i].length))
                      }
         
                      let subArray
                      let total=0;
                     
                      for(let i=0;i<parseInt(array.length);i++){
                         
                         total=total+parseInt(array[i])
                          if(parseInt(index)<=total)
                          {
                          subArray=i;
                          break;
                          }
                      }
                  
         
                     let final=0;
                     
                      for(let i=0;i<subArray;i++)
                      {
                          final=final+array[i]
                      }
                     let position=index-final-subArray
                  // console.log(array,subArray,"subarray",index,"index",position,"position")
                     
                    if(object.styles[subArray]){
                          let obj=object.styles
         
         
                    if(obj[subArray][position])
                    {
                   this.setState({activeFontFamily:obj[subArray][position]?.fontFamily})
                   let canvas=this.props.state.canvas
                  
            
                    this.setState({
                        value:obj[subArray][position].fontSize?obj[subArray][position].fontSize:object.fontSize
                    });
           
                  this.setState({textColor:obj[subArray][position]?.fill})

                  if(this.textcolorRef.current)
                  this.textcolorRef.current.style.background = obj[subArray][position]?.fill;
                   canvas.renderAll()
                    }
                    else if(!obj[subArray][position]){
                      this.setState({activeFontFamily:object.fontFamily,value:object.fontSize,textColor:object.fill})
                      this.textcolorRef.current.style.background = object.fill;
         
                      canvas.renderAll()
                    }
                  }
                  else{
                      this.setState({activeFontFamily:object.fontFamily,value:object.fontSize,textColor:object.fill})
                      this.textcolorRef.current.style.background =object.fill

                      canvas.renderAll()
                    }
            
                 
                  
                     subArray=null;
                     final=0;
                     array=[]
         
                }

                else if(!object.isEditing){
                    let objKey=Object.values(object.styles)
                    if(objKey.length>0){
                  
                        let myFind2
                        let objLen1=Object.values(object?.styles)
                 
                         if(objLen1?.length>0){
                             let temp1=Object.values(object?.styles);
                             let temp2=temp1[0];
                             let temp3=Object.values(temp2);
                             let find=temp3.find((obj)=>{return obj.fontFamily!==object.fontFamily})
                           if(find!==null){
                               this.setState({differentFont:true})
                               myFind2=true
                           }
                           else{
                               this.setState({differentFont:false})
                               myFind2=false
                           }
                        
                             }
                       

                             this.setState({activeFontFamily:myFind2?" ":object.fontFamily,value:myFind2?" ":object.fontSize,textColor:object.fill})
                             canvas.renderAll()
                    }

                    else{
                        this.setState({activeFontFamily:object.fontFamily,value:object.fontSize,textColor:object.fill})
                        canvas.renderAll()
                    }
                }
           
         }
     }
    

      afterRender=()=>{
        let canvas=this.props.state.canvas
        canvas.getActiveObjects().forEach(function(obj){
            obj.setCoords();
        })
      }          
                

    lock = () => {
        this.setState({
            lock: true
        })
        var canvas = this.props.state.canvas;

        if (canvas._activeObject) {
            canvas._activeObject.isLocked = true
            canvas._activeObject.lockMovementX = true;
            canvas._activeObject.lockMovementY = true;
            canvas._activeObject.lockScalingX = true;
            canvas._activeObject.lockScalingY = true;
            canvas._activeObject.lockRotation = true;
            canvas.renderAll();
           
                if(this.props.state.canvas){
                    let frontCanvas = getCanvasBySide(
                        this.props.allCanvasList,
                        "front"
                    );
            
                    let backCanvas=getCanvasBySide(
                        this.props.allCanvasList,
                        "back"
                    );
                    addToHistory(frontCanvas)
                    addToHistory2(backCanvas)
                    // removeStates()
                    // removeStates2()
                    }
       
           
        }
    };
    unlock = () => {
        this.setState({
            lock: false
        })
        var canvas = this.props.state.canvas;
        if (canvas._activeObject) {
            canvas._activeObject.isLocked = false
            canvas._activeObject.lockMovementX = false;
            canvas._activeObject.lockMovementY = false;
            canvas._activeObject.lockScalingX = false;
            canvas._activeObject.lockScalingY = false;
            canvas._activeObject.lockRotation = false;
            canvas.renderAll();
          
                if(this.props.state.canvas){
                    let frontCanvas = getCanvasBySide(
                        this.props.allCanvasList,
                        "front"
                    );
            
                    let backCanvas=getCanvasBySide(
                        this.props.allCanvasList,
                        "back"
                    );
                    addToHistory(frontCanvas)
                    addToHistory2(backCanvas)
                    // removeStates()
                    // removeStates2()
                    }
          
        }
    };
    pickerClose = () =>{
        this.setState({
            displayColorPicker: false
        })
    };

    strokepickerOpen = () =>{
        this.setState({
            displaystrokeColorPicker: !this.state.displaystrokeColorPicker
        })
    };

    strokepickerClose = () =>{
        this.setState({
            displaystrokeColorPicker: false
        })
    };

    glowpickerOpen = () =>{
        this.setState({
            displayglowColorPicker: !this.state.displayglowColorPicker
        })
    };

    glowpickerClose = () => {
        this.setState({
            displayglowColorPicker: false
        })
    };

    setStroke = (color) => {
        this.changeObjectproperty('stroke', color.hex);
        this.setState({
            strokeColor: color.hex
        });
        this.textstrokecolRef.current.style.background = color.hex;
    };

    changeObjectColor = (hex) => {
        // this.changeObjectproperty('fill', hex);
    }

    changeObjectproperty(style, hex) {
        let lthis = this;
        var canvas = this.props.state.canvas;
        let obj = canvas.selectedObject;

        if (!obj)
            obj = canvas.getActiveObject();

        if (obj) {
            if (obj.paths) {
                for (let i = 0; i < obj.paths.length; i++) {
                    this.setActiveStyle(style, hex, obj.paths[i]);
                }
            } else if (obj.type === "group") {
                let objects = obj.getObjects();
                for (let i = 0; i < objects.length; i++) {
                    this.setActiveStyle(style, hex, objects[i]);
                }
            }
            else this.setActiveStyle(style, hex, obj);
        } else {
            let grpobjs = canvas.getActiveObjects();
            if (grpobjs) {
                grpobjs.forEach(function (object) {
                    if (object.paths) {
                        for (let i = 0; i < object.paths.length; i++) {
                            lthis.setActiveStyle(style, hex, obj.paths[i]);
                        }
                    }
                    else lthis.setActiveStyle(style, hex, obj);
                });
            }
        }
        canvas.renderAll();
        saveCanvasState(canvas);
    }   

    fontSize = (event) => {
        this.setState({
            value: event.target.value
        });
        this.setActiveStyle('fontSize', event.target.value);
        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );
            addToHistory(frontCanvas)
            addToHistory2(backCanvas)
            // removeStates()
            // removeStates2()
            }
    }

    clone = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (!activeObject) return false;
        if (activeObject.type === 'activeSelection') {
            activeObject.forEachObject((object) => {
                this.cloneSelObject(object);
            });
        }
        else {
            this.cloneSelObject(activeObject);
        }
    }

    cloneSelObject = (actobj) => {
        var canvas = this.props.state.canvas;
        canvas.discardActiveObject();
        // if (fabric.util.getKlass(actobj.type).async) {
        //     var clone = fabric.util.object.clone(actobj);
        //     clone.set({
        //         id:Math.floor(Math.random() * 1000),
        //         top: actobj.top + 30
        //     });
        //     canvas.add(clone);
        //     saveCanvasState(canvas);
       
        // } else {
        //     var clones = fabric.util.object.clone(actobj);
        //     canvas.add(clones.set({
        //         id:Math.floor(Math.random() * 1000),
        //         top: actobj.top + 30
        //     }));
        //     saveCanvasState(canvas);
         
        // }

        actobj.clone(function (o) {
            var vobj = o;
            if (vobj) {
                vobj.set({
            
                    top:actobj.top + 30
                });
                canvas.add(vobj);
                canvas.renderAll();
                canvas.calcOffset();
            } 
        });
        
        canvas.requestRenderAll();
        if(this.props.state.canvas){
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props.allCanvasList,
                "back"
            );

            setTimeout(()=>{
                addToHistory(frontCanvas)
                addToHistory2(backCanvas)
            },(300))
          
            // removeStates()
            // removeStates2()
            }
      
    }

    setOpacity = (event) => {
        this.setState({
            opacityval: event.target.value
        });
        this.setActiveStyle('opacity', event.target.value / 100);
    }

    setStrokeval = (event) => {
        // console.log(event.target.value);
        this.setState({
            strokeval: event.target.value
        });
        this.changeObjectproperty('strokeWidth', event.target.value * 1);
    }

    outlinetoggle = () => {
        this.setState({
            collapse: !this.state.collapse
        })
        if (this.state.collapse === true) {
            this.changeObjectproperty('strokeWidth', null);
            this.changeObjectproperty('stroke', null);
        } else {
            this.changeObjectproperty('strokeWidth', this.state.strokeval);
            this.changeObjectproperty('stroke', this.state.strokeColor);
        }
    }

    setGlow = (color) => {
        this.textglowcolRef.current.style.background = color.hex;
        this.setState({
            glowColor: color.hex
        });
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (activeObject) {
            activeObject.setShadow({
                color: color.hex,
                blur: 1,
                offsetX: 1,
                offsetY: 1
            });
        }
        canvas.renderAll();
    }

    shapeUpload = (event) => {
        var canvas = this.props.state.canvas;
        var reader = new FileReader();
        reader.onload = function (event) {
            var image = new Image();
            image.src = event.target.result;
            image.onload = function () {
                var img = new fabric.Image(image);
                img.set({
                    id:Math.floor(Math.random() * 1000),
                    left: 100,
                    top: 100
                });
                var activeObject = canvas.getActiveObject();
                activeObject.set('fill', new fabric.Pattern({
                    source: image,
                    repeat: 'repeat'
                }));
                canvas.renderAll();
            }
        }
        reader.readAsDataURL(event.target.files[0]);
    }

    setglowblur = (event) => {
        this.setState({
            blurval: event.target.value
        });
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (activeObject) {
            activeObject.setShadow({
                blur: event.target.value,
                color: this.state.glowColor,
                offsetX: this.state.offsetX,
                offsetY: this.state.offsetY,
            });
        }
        canvas.renderAll();
    }

    setglowoffsetX = (event) => {
        this.setState({
            offsetX: event.target.value
        });
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (activeObject) {
            activeObject.setShadow({
                blur: this.state.blurval,
                color: this.state.glowColor,
                offsetX: event.target.value,
                offsetY: this.state.offsetY,
            });
        }
        canvas.renderAll();
    }

    setglowoffsetY = (event) => {
        this.setState({
            offsetY: event.target.value
        });
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (activeObject) {
            activeObject.setShadow({
                blur: this.state.blurval,
                color: this.state.glowColor,
                offsetX: this.state.offsetX,
                offsetY: event.target.value
            });
        }
        canvas.renderAll();
    }

    glowtoggle = () => {
        this.setState({
            glowcollapse: !this.state.glowcollapse
        })
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (this.state.glowcollapse === true) {
            if (activeObject) {
                activeObject.setShadow({
                    color: '',
                    blur: 0,
                    offsetX: 0,
                    offsetY: 0
                });
            }
            canvas.renderAll();
        } else {
            if (activeObject) {
                activeObject.setShadow({
                    color: this.state.glowColor,
                    blur: this.state.blurval,
                    offsetX: this.state.offsetX,
                    offsetY: this.state.offsetY
                });
            }
            canvas.renderAll();
        }
    }

    bringForward = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        var grpobjs = canvas.getActiveObjects();
        if (grpobjs) {
            grpobjs.forEach((object) => {
                if(object?.isLocked !== true){
                    canvas.bringForward(object);
                }
                canvas.renderAll();
                saveCanvasState(canvas);
                if(this.props.state.canvas){
                    let frontCanvas = getCanvasBySide(
                        this.props.allCanvasList,
                        "front"
                    );
            
                    let backCanvas=getCanvasBySide(
                        this.props.allCanvasList,
                        "back"
                    );
                    addToHistory(frontCanvas)
                    addToHistory2(backCanvas)
                    // removeStates()
                    // removeStates2()
                    }
            });
        } else {

            if(activeObject?.isLocked !== true){
                canvas.bringForward(activeObject);
            }
            canvas.renderAll();
            saveCanvasState(canvas);
            if(this.props.state.canvas){
                let frontCanvas = getCanvasBySide(
                    this.props.allCanvasList,
                    "front"
                );
        
                let backCanvas=getCanvasBySide(
                    this.props.allCanvasList,
                    "back"
                );
                addToHistory(frontCanvas)
                addToHistory2(backCanvas)
                // removeStates()
                // removeStates2()
                }

        }
    }

    sendBackward = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        var grpobjs = canvas.getActiveObjects();
        if (grpobjs) {
            grpobjs.forEach((object) => {
                if(object?.isLocked !== true){
                    canvas.sendBackwards(object);
                }

                canvas.renderAll();
                saveCanvasState(canvas);
                if(this.props.state.canvas){
                    let frontCanvas = getCanvasBySide(
                        this.props.allCanvasList,
                        "front"
                    );
            
                    let backCanvas=getCanvasBySide(
                        this.props.allCanvasList,
                        "back"
                    );
                    addToHistory(frontCanvas)
                    addToHistory2(backCanvas)
                    // removeStates()
                    // removeStates2()
                    }
            });
        } else {
            if(activeObject?.isLocked !== false){
                canvas.sendBackwards(activeObject);
            }
            canvas.renderAll();
            saveCanvasState(canvas);
            if(this.props.state.canvas){
                let frontCanvas = getCanvasBySide(
                    this.props.allCanvasList,
                    "front"
                );
        
                let backCanvas=getCanvasBySide(
                    this.props.allCanvasList,
                    "back"
                );
                addToHistory(frontCanvas)
                addToHistory2(backCanvas)
                // removeStates()
                // removeStates2()
                }
        }
    }

    setWidth = () => {
        var canvas = this.props.state.canvas;
        $(".width").click(function () {
            //var widdth = $(this).val()



            //var activeObject = canvas.getActiveObject();

            var grpobjs = canvas.getActiveObjects();

            if (grpobjs) {
                grpobjs.forEach((object) => {

                    if (object.width) {
                        object.width = object.width + 5;
                        object.radius = object.radius + 5;
                        // this.setState({
                        //     activeObjectHeight:object.width
                        // })
                    }
                    else {
                        object.width = object.width + 5;
                        object.radius = object.radius + 5;
                    }
                    canvas.renderAll();
                    saveCanvasState(canvas);
                });
            }
            // else {
            //     if (activeObject.width) activeObject.width = object.width+10;
            //     else activeObject.width = object.width+10;
            //     canvas.renderAll();
            //     saveCanvasState(canvas);

            // }
        });
    }

    setHeight = () => {
        // $(".width").click(function () {
        // var canvas = this.props.state.canvas;
        // var activeObject = canvas.getActiveObject();

        // var grpobjs = canvas.getActiveObjects();

        // if (grpobjs) {
        //     grpobjs.forEach((object) => {

        //         if (object.height)
        //         {object.height = object.height+5;
        //             object.radius = object.radius+5;
        //             this.setState({
        //                 activeObjectHeight:object.height
        //             })
        //         }
        //         else {
        //             object.height = object.height+5;
        //             object.radius = object.radius+5;
        //         }
        //         canvas.renderAll();
        //         saveCanvasState(canvas);
        //     });
        // }
        // // else {
        // //     if (activeObject.width) activeObject.width = object.width+10;
        // //     else activeObject.width = object.width+10;
        // //     canvas.renderAll();
        // //     saveCanvasState(canvas);

        // // }
        // });
    }
    horizontalflip = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        var grpobjs = canvas.getActiveObjects();
        if (grpobjs) {
            grpobjs.forEach((object) => {
                if (object.flipX) object.flipX = false;
                else object.flipX = true;
                canvas.renderAll();
                saveCanvasState(canvas);
            });
        } else {
            if (activeObject.flipX) activeObject.flipX = false;
            else activeObject.flipX = true;
            canvas.renderAll();
            saveCanvasState(canvas);

        }
    }

    verticalflip = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        var grpobjs = canvas.getActiveObjects();
        if (grpobjs) {
            grpobjs.forEach((object) => {
                if (object.flipY) object.flipY = false;
                else object.flipY = true;
                canvas.renderAll();
                saveCanvasState(canvas);
            });
        } else {
            if (activeObject.flipY) activeObject.flipY = false;
            else activeObject.flipY = true;
            canvas.renderAll();
            saveCanvasState(canvas);

        }
    }

    groupItems = () => {
        var canvas = this.props.state.canvas;
        if (!canvas.getActiveObject()) {
            return;
        }
        if (canvas.getActiveObject().type !== 'activeSelection') {
            return;
        }
        canvas.getActiveObject().toGroup();
        selectObject(canvas);
        canvas.renderAll();
    }

    unGroupItems = () => {
        var canvas = this.props.state.canvas;
        if (!canvas.getActiveObject()) {
            return;
        }
        if (canvas.getActiveObject().type !== 'group') {
            return;
        }
        canvas.getActiveObject().toActiveSelection();
        selectObject(canvas);
        canvas.renderAll();
    }

    popupClose = () => {
        this.setState({
            glowcollapse: false,
            open: false,
            collapse: false,
            outlinechecked: false,
            glowchecked: false
        })
    }

    popupOpen = () => {

        this.setState({
            open: true
        });
    }

    outlineCheckBox = (e) => {
        this.setState({
            outlinechecked: e.target.checked
        })
    }

    glowCheckBox = (e) => {
        this.setState({
            glowchecked: e.target.checked
        })
    }


   cropCancel=()=>{
    var canvas=this.props.state.canvas;
    var currentImage=this.state.currentImage;
    var selectionRect=canvas.getObjects().find((item)=>{return item.name==="selectionRect"})
    if(selectionRect)
    {
    canvas.remove(selectionRect)
    }
    if(currentImage.clipPath)
    {
        currentImage.set({clipath:null})
    }
    this.setState({isCropping:false})

   }

    cropDone=()=>{
    
        var canvas=this.props.state.canvas;
        let zoomLevel = $("#my-range").val()
        zoomLevel=zoomLevel/100;
        var currentImage=this.state.currentImage;
        var tempCanvas1 = new fabric.Canvas('canvas-ele', {
            width: fabric.Canvas.originalWidth,
            height:fabric.Canvas.originalHeight
        });
        tempCanvas1.set({height:fabric.Canvas.originalHeight*4,width:fabric.Canvas.originalWidth*4})
        tempCanvas1.setZoom(4)
        
                     
            tempCanvas1.add(currentImage);
            tempCanvas1.renderAll()
        var selectionRect=canvas.getObjects().find((item)=>{return item.name==="selectionRect"})
        selectionRect.set({fill:null,opacity:0})
        tempCanvas1.add(selectionRect);


          var value=selectionRect.getBoundingRect();

          
          currentImage.setCoords()

        currentImage.selectable = true;

        var cropped = new Image();

        cropped.src = tempCanvas1.toDataURL({
            left: value.left,
            top: value.top,
            width: value.width,
            height: value.height,
            quality:1.0
        });

        cropped.onload = function() {
           var image = new fabric.Image(cropped);
          
        //    image.height=value.height;
        //    image.width=value.width;

        image.set({top:30,left:10,height:value.height,width:value.width})
        var scale;
        if(selectionRect.getScaledWidth()<image.getScaledWidth()){
        scale=(selectionRect.getScaledWidth())/image.getScaledWidth()
       image.set({top:selectionRect.top,left:selectionRect.left,scaleX:scale,scaleY:scale})

        }
        else if(selectionRect.getScaledHeight()<image.getScaledHeight())
        {
         scale=(selectionRect.getScaledHeight())/image.getScaledHeight()
        image.set({top:value.top,left:value.left,scaleY:scale,scaleX:scale})
        }


        
      
        tempCanvas1.set({height:fabric.Canvas.originalHeight*zoomLevel,width:fabric.Canvas.originalWidth*zoomLevel})
        tempCanvas1.setZoom(zoomLevel)
      
setTimeout(()=>{
    canvas.add(image);
    image.setCoords();

    canvas.renderAll();


},(100))

          
        };
     


       canvas.remove(currentImage)
        canvas.remove(selectionRect);
        tempCanvas1.remove(selectionRect);

        tempCanvas1.remove(currentImage)

        tempCanvas1.renderAll()


          canvas.renderAll();
          if(canvas){
            let frontCanvas = getCanvasBySide(
                this.props?.allCanvasList,
                "front"
            );
    
            let backCanvas=getCanvasBySide(
                this.props?.allCanvasList,
                "back"
            );
            
            setTimeout(()=>{
                addToHistory(frontCanvas)
                addToHistory2(backCanvas)
            },(300))
           
            // removeStates()
            // removeStates2()
            }
    this.setState({isCropping:false})


    }

    // cropDone=()=>{
    //     let canvas=this.props.state.canvas;
    //     let currentImage=this.state.currentImage;
    //    let secondaryImage=this.props.setImage;
    //    console.log("SetImage",secondaryImage)
    
    //     let scalex=(secondaryImage.width/secondaryImage.getScaledWidth());
    //     let scaley=(secondaryImage.height/secondaryImage.getScaledHeight())
    

    //     let tempCanvas1 = new fabric.Canvas('canvas-ele', {
    //         height:secondaryImage.height,
    //         width:secondaryImage.width})

    //         console.log("Scales",currentImage.width,secondaryImage.width,currentImage.getScaledWidth(),secondaryImage.getScaledWidth(),currentImage.height,secondaryImage.height,currentImage.getScaledHeight(),secondaryImage.getScaledHeight(),currentImage.scaleY,secondaryImage.scaleY,currentImage.scaleX,secondaryImage.scaleX,currentImage)
    
       
    //      currentImage.set({left:0,top:0,scaleX:secondaryImage.scaleX*scalex,scaleY:secondaryImage.scaleY*scaley})       
    //       tempCanvas1.add(currentImage);
    
    //       let obj=tempCanvas1.toDataURL();
    //       console.log("obj",obj)
    //       tempCanvas1.renderAll();
    
    // }




    editCrop=()=>{

        var canvas=this.props.state.canvas;
        // canvas.preserveObjectStacking = true;

        var activeObject=canvas.getActiveObject();
         if(activeObject.clipPath){
             activeObject.clipPath=null
         }
        if(activeObject.type==="image"){
        this.addSelectionRect(activeObject,canvas);
        }
    }

    addSelectionRect=(activeObject,canvas)=>{
     this.setState({currentImage:activeObject})
       var selectionRect = new fabric.Rect({
           top:activeObject.top,
           left:activeObject.left,
            fill: 'rgba(0,0,0,0.3)',
            originX: 'left',
            originY: 'top',
            stroke: 'black',
            opacity: 1,
            height:activeObject.getScaledHeight()/2,
            width:activeObject.getScaledWidth()/2,
            hasRotatingPoint: false,
            transparentCorners: false,
            cornerColor: 'white',
            cornerStrokeColor: 'black',
            borderColor: 'black',
            cornerSize: 12,
            padding: 0,
            cornerStyle: 'circle',
            borderDashArray: [5, 5],
            borderScaleFactor: 1.3,
            name:"selectionRect"
          });
          selectionRect.visible = true;
          canvas.add(selectionRect);
          canvas.setActiveObject(selectionRect);

    }



    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
            top: '40px',
            left: '282px',
        }
        const popover2 = {
            position: 'absolute',
            zIndex: '2',
            top: '40px',

        }


        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        const strokepopover = {
            position: 'absolute',
            zIndex: '2',
            top: '150px',
            left: '140px',
        }
        const strokecover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        /*const glowpopover = {
            position: 'absolute',
            zIndex: '2',
            top: '150px',
            left: '140px',
        }
        const glowcover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }*/

        const { canvas } = this.props.state;
        let isObjectLocked = false
        //const { activeObjectWidth, activeObjectHeight } = this.state;
        if (canvas) {
            const activeObject = canvas.getActiveObject();

            if (!activeObject) {
                return null;
            } else {
                isObjectLocked = activeObject?.isLocked ? activeObject.isLocked: false
            }
        } else {
            return null;
        }




        return (
            <div>
            {($(window).width()>568)?
                <Container className="toolbar-container" style={{minHeight:"77px"}}>

                <span className="leftbar">
                     { canvas.getActiveObject().type==="textbox" &&
                     
                    <div title="Font Family" className="fontFamily" style={{height:"26px"}}>
                        {/* <FontPicker
                            ref={this.pickerRef}
                            apiKey="AIzaSyCOyeDUsAnL-jnWudXBKNNma9cXmXsT4tM"
                            activeFontFamily={this.state.activeFontFamily}
                            families={fontFamilyNames}
                            limit="150"
                            onChange={nextFont => {this.setTextFont(nextFont.family)
                            }}
                        /> */}

                        <select value={this.state.activeFontFamily} onChange={this.setTextFont} >
                            {this.state.fontFamilyNames.map((item)=>{
                            return <option style={{fontFamily:item}}>{item}</option>
                            })}
                        </select>
                    </div>}
 { canvas.getActiveObject().type==="textbox" &&
                    <div className="select-container  fontsize mx-1" title="Font Size"  style={{height:"26px"}}>
                        &nbsp;&nbsp;<select onChange={this.fontSize} value={this.state.value} style={{ border: '2px solid #ECECEC',outLine:"none" }}>
                        {this.state.fontSizes.map((item)=>{
                           return <option>{item}</option>
                              })}
                        </select>
 
                    </div>
    }
    { canvas.getActiveObject().type==="textbox" &&
                   <span>
                    <div className="font-color-container  colorpic px-1" onClick={this.pickerOpen} title="Font/Fill Color"  style={{height:"26px"}}>
                        <div className="colrsec" onClick={this.pickerOpen}>
                            <div ref={this.textcolorRef} className="primcol textcolpick" />
                            &nbsp;&nbsp;<img className="arrowimg  textcolpick" src={require('../images/down-arrow.png')} alt="" onClick={this.pickerOpen} />
                        </div>
                    </div>

                    {this.state.displayColorPicker
                        ? <div style={popover} >
                            <div style={cover} onClick={this.pickerClose} />
                            <SketchPicker color={this.state.textColor} onChangeComplete={this.setColor} />
                        </div>
                        : null
                    }
                    </span>
                }              
    
                    <div className="font-style-container">
                        <div className="colrsec" title="Rotate">
                            <img id="icon" src={require('../images/rotate.png')} height="20px" width="20px" onClick={this.rotate} style={{ cursor: "pointer" }} alt="" />
                        </div>
                        {!isObjectLocked ? <div className="colrsec" title="Lock">
                            <img id="icon" src={process.env.PUBLIC_URL + '/assets/images/lock.jpg'} height="20px" width="20px" onClick={this.lock} style={{ cursor: "pointer" }} alt="Click to Lock" />
                        </div>: ''}
                        {isObjectLocked ? <div className="colrsec" title="Unlock">
                            <img id="icon" src={process.env.PUBLIC_URL + '/assets/images/unlock.png'} height="20px" width="20px" onClick={this.unlock} style={{ cursor: "pointer" }} alt="Click to Unlock" />
                        </div>: ''}
                    </div>
                    { canvas.getActiveObject().type==="textbox" &&
                    <>
                    <div className="font-style-container">
                        <div title="Bold" onClick={this.setTextBold} className={`  ${
                        this.state.canvasObj?.fontWeight === 'bold' ? 'activetext' : null
                    }`}>
                            <img className="toolbar-icon " src={require('../images/bold.png')} alt="" />
                        </div>
                        <div title="Italic" onClick={this.setTextItalic} className={`  ${
                        this.state.canvasObj?.fontStyle === 'italic' ? 'activetext' : null
                    }`}>
                            <img className="toolbar-icon titalic" src={require('../images/italic.png')} alt="" />
                        </div>
                        <div title="Underline" onClick={this.setTextUnderline}  className={`  ${
                        this.state.canvasObj?.underline === "underline" ? 'activetext' : null
                    }`}>
                            <img className="toolbar-icon tunder" src={require('../images/underline.png')} alt="" />
                        </div>
                        <div title="Strike out" onClick={this.setTextStrike}  className={`  ${
                        this.state.canvasObj?.linethrough === "linethrough" ? 'activetext' : null
                    }`}>
                            <img className="toolbar-icon tstrike" src={require('../images/strikethrough.png')} alt="" />
                        </div>
                        <div title="Bulleted List" onClick={this.setBulletText}  className={`${
                        this.state.canvasObj?.listType === "bullet" ? 'activetext' : null
                    }`}>
                            <img className="toolbar-icon tbullet" src={require('../images/list-bullet.png')} alt="" />
                        </div>
                        <div title="Ordered List" onClick={this.setNumberText}  className={`${
                        this.state.canvasObj?.listType === "number" ? 'activetext' : null
                    }`}>
                            <img className="toolbar-icon tNumber" src={require('../images/orderlist.jpg')} alt="" />
                        </div>

                    </div>

                    <div className="font-style-container">
                        <div title="Left" onClick={this.alignObjectLeft}  className={` ${
                        this.state?.canvasObj?.textAlign === 'left' ? 'activetext' : null
                    }`}>
                            <img className="toolbar-icon tleft" src={require('../images/align-left-50.png')} alt="" />
                        </div>
                        <div title="Center" onClick={this.alignObjectCenter} className={`  ${
                        this.state?.canvasObj?.textAlign === 'center' ? 'activetext' : null
                    }`}>
                            <img className="toolbar-icon tcenter" src={require('../images/align-center-50.png')} alt="" />
                        </div>
                        <div title="Right" onClick={this.alignObjectRight} className={` ${
                        this.state?.canvasObj?.textAlign === 'right' ? 'activetext' : 'null'
                    }`} src={require('../images/align-right-50.png')} >
                            <img   className={`toolbar-icon `} src={require('../images/align-right-50.png')} alt="" />
                        </div>
                    </div>
                    </>
                    }

                    <div className="overlap-container">
                        <div title="Send Back" onClick={this.sendBackward} className="sendback">
                            <img className="overlapArrow" src={require('../images/send-backward.svg')} alt="" />
                        </div>
                        <div title="Bring Forward" onClick={this.bringForward} className="sendforward ">
                            <img className="overlapArrow" src={require('../images/bring-forward.svg')} alt="" />
                        </div>
                    </div>

                    {/* <div className="overlap-container">
                        <div title="Group" onClick={this.groupItems} className="sendforward group">
                            <i className="arrow fa fa-object-group" aria-hidden="true"></i>
                        </div>
                        <div title="Ungroup" onClick={this.unGroupItems} className="sendforward ungroup">
                            <i className="arrow fa fa-object-ungroup" aria-hidden="true"></i>
                        </div>
                    </div> */}

                    {/* <div className="overlap-container">
                        <div title="Horizontalflip" onClick={this.horizontalflip} className="horizontalflip">
                            <i className="fas fa-ruler-vertical fa-rotate-270" aria-hidden="true"></i>
                        </div>
                        <div title="Verticalflip" onClick={this.verticalflip} className="verticalflip">
                            <i className="fas fa-ruler-vertical"></i>
                        </div>
                    </div> */}

                    {/* <div title="Effects" className="txteff">
                        <Popup onOpen={this.popupOpen} onClose={this.popupClose} className="popupcontent"
                            trigger={<div className="toolbar-label">Effects</div>}
                            position="bottom center"
                            closeOnDocumentClick
                        >
                            <div className="effects">
                                <div className="opacity-section">
                                    <div className="toolbar-label opacity">Opacity</div>
                                    <div className="slider-view">
                                        <input
                                            type="range"
                                            className="slider opacityslider"
                                            max="100"
                                            min="0"
                                            step="5"
                                            onChange={this.setOpacity}
                                            value={this.state.opacityval}
                                        />
                                        <div>{this.state.opacityval}%</div>
                                    </div>
                                </div>

                                <div className="effsection separator">
                                    <div className="toolbar-label">Outline</div>
                                    <div className="control">
                                        <input type="checkbox" id="switch" onChange={this.outlineCheckBox} checked={this.state.outlinechecked} />
                                        <label htmlFor="switch" onClick={this.outlinetoggle}>Toggle</label>
                                    </div>
                                </div>
                                <Collapse isOpen={this.statecollapse} className="strokesection">
                                    <div className="effsection">
                                        <div className="toolbar-label">Color</div>
                                        <div className="font-color-container">
                                            <div className="colrsec" onClick={this.strokepickerOpen}>
                                                <div ref={this.textstrokecolRef} className="primcol" style={{ background: this.state.backgroundcol }} />
                                                <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                            </div>
                                        </div>
                                        {this.state.displaystrokeColorPicker
                                            ? <div style={strokepopover}>
                                                <div style={strokecover} onClick={this.strokepickerClose} />
                                                <SketchPicker color={this.state.strokeColor} onChangeComplete={this.setStroke} />
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className="effsection">
                                        <div className="toolbar-label">Width</div>
                                        <div className="slider-view">
                                            <input
                                                type="range"
                                                className="slider widthslider"
                                                max="5"
                                                min="1"
                                                step="1"
                                                onChange={this.setStrokeval}
                                                value={this.state.strokeval}
                                            />
                                            <div>{this.state.strokeval}</div>
                                        </div>
                                    </div>
                                </Collapse>

                                <div className="effsection separator">
                                    <div className="toolbar-label">Glow</div>
                                    <div className="control">
                                        <input type="checkbox" id="switch1" onChange={this.glowCheckBox} checked={this.state.glowchecked} />
                                        <label htmlFor="switch1" onClick={this.glowtoggle}>Toggle</label>
                                    </div>
                                </div>
                                <Collapse isOpen={this.state.glowcollapse} className="glowsection">
                                    <div className="effsection">
                                        <div className="toolbar-label">Color</div>
                                        <div className="font-color-container">
                                            <div className="colrsec" onClick={this.glowpickerOpen}>
                                                <div ref={this.textglowcolRef} className="primcol" style={{ background: this.state.glowbackgroundcol }} />
                                                <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                            </div>
                                        </div>
                                        {this.state.displayglowColorPicker
                                            ? <div style={glowpopover}>
                                                <div style={glowcover} onClick={this.glowpickerClose} />
                                                <SketchPicker color={this.state.glowColor} onChangeComplete={this.setGlow} />
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className="effsection">
                                        <div className="toolbar-label">offsetX</div>
                                        <div className="slider-view">
                                            <input
                                                type="range"
                                                className="slider widthslider"
                                                max="10"
                                                min="1"
                                                step="1"
                                                onChange={this.setglowoffsetX}
                                                value={this.state.offsetX}
                                            />
                                            <div>{this.state.offsetX}%</div>
                                        </div>
                                    </div>
                                    <div className="effsection">
                                        <div className="toolbar-label">offsetY</div>
                                        <div className="slider-view">
                                            <input
                                                type="range"
                                                className="slider widthslider"
                                                max="10"
                                                min="1"
                                                step="1"
                                                onChange={this.setglowoffsetY}
                                                value={this.state.offsetY}
                                            />
                                            <div>{this.state.offsetY}%</div>
                                        </div>
                                    </div>
                                    <div className="effsection">
                                        <div className="toolbar-label">Blur</div>
                                        <div className="slider-view">
                                            <input
                                                type="range"
                                                className="slider widthslider"
                                                max="10"
                                                min="1"
                                                step="1"
                                                onChange={this.setglowblur}
                                                value={this.state.blurval}
                                            />
                                            <div>{this.state.blurval}%</div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </Popup>
                    </div> */}

                    {/* <div title="Effects" className="elementeff">
                        <Popup className="elepopupcontent"
                            trigger={<div className="toolbar-label">Effects</div>}
                            position="bottom center"
                            closeOnDocumentClick
                        >
                            <div className="effects">
                                <div className="opacity-section">
                                    <div className="toolbar-label opacity">Opacity</div>
                                    <div className="slider-view">
                                        <input
                                            type="range"
                                            className="slider opacityslider"
                                            max="100"
                                            min="0"
                                            step="5"
                                            onChange={this.setOpacity}
                                            value={this.state.opacityval}
                                        />
                                        <div>{this.state.opacityval}%</div>
                                    </div>
                                </div>

                            </div>
                        </Popup>
                    </div> */}

                    <div title="Effects" className="strokeeff">
                        <Popup className="popupcontent"
                            trigger={<div className="toolbar-label">Stroke</div>}
                            position="bottom center"
                            closeOnDocumentClick>
                            <span>
                                <div className="effects">
                                    <div className="effsection">
                                        <div className="toolbar-label stroke-label">Stroke Color</div>
                                        <div className="font-color-container">
                                            <div className="colrsec" onClick={this.strokepickerOpen}>
                                                <div ref={this.textstrokecolRef} className="primcol strokecolpick" />
                                                <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                            </div>
                                            {
                                                this.state.displaystrokeColorPicker
                                                    ? <div style={strokepopover} className="strokecolpic">
                                                        <div style={strokecover} onClick={this.strokepickerClose} />
                                                        <SketchPicker color={this.state.strokeColor} onChangeComplete={this.setStroke} />
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="effsection">
                                        <div className="toolbar-label stroke-label">Stroke Width</div>
                                        <div className="slider-view">
                                            <input
                                                type="range"
                                                className="slider strokeslider"
                                                max="10"
                                                min="1"
                                                step="1"
                                                onChange={this.setStrokeval}
                                                value={this.state.strokeval}
                                            />
                                            <div>{this.state.strokeval}%</div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </Popup>
                    </div>
                </span>
                <span className="rightbar">
                    {/* <div>W:<input className="width" style={{ width: "70px", border: "1px solid #c1c1c1" }} type="number" name="width" id="width" onChange={this.setWidth} min="200" max="5000" step="5" /></div>
                    <div>H:<input style={{ width: "70px", border: "1px solid #c1c1c1" }} type="number" name="height" id="height" value={activeObjectHeight} onChange={this.setHeight} /></div> */}
                    {/* <div title="Clone the Item" className="colrsec btn-duplicate" onClick={this.clone}><img className="toolbar-icon tNumber" style={{height:"20px",width:"20px"}} src={require('../images/clone.png')} alt="" /></div> */}
                    <div className='px-1'>
                    {canvas.getActiveObject().type==="image" && this.state.isCropping===false? <div title="Crop the Item" className="toolbar-label btn-duplicate" onClick={()=>{       
                        this.editCrop();
                        this.setState({isCropping:true})}}><img className="toolbar-icon tNumber" style={{height:"20px",width:"20px"}} src={require('../images/crop.png')} alt="" /></div>:null}
                    {this.state.isCropping?<span className='colrsec btn-duplicate' onClick={()=>this.cropDone()}>Done</span>:null}
                    {this.state.isCropping?<span className='colrsec btn-duplicate' onClick={()=>this.cropCancel()}>Cancel</span>:null}

                    </div>
                    <div className='px-1'>
                    {canvas.getActiveObject().type==="image" ?
                    <div title="Mask the Image" className="colrsec btn-duplicate" onClick={()=>{this.props.setMasking();this.setState({selectedCanvasObject:this.props.state.canvas.getActiveObject()})}}><img className="toolbar-icon tNumber" style={{height:"20px",width:"20px"}} src={require('../images/circle-in-square.png')} alt="" /></div>
                    :null}
                    </div>
                    <span title="Delete" id="delete" className={`btn-delete`} onClick={isObjectLocked? null : this.deleteItem}>
                        <i className="far fa-trash-alt text arrow"  style={{color:isObjectLocked ? "grey" : "red"}}/>
                    </span>
                </span>


            </Container>:
            <Container className="toolbar-container" style={{minHeight:"77px",position:"inherit"}}>

                   <div className="row ">

                    <div className='col-12' style={{textAlign:"left"}}>
                       <span title="Font Family" className="" >
                       <select value={this.state.activeFontFamily} onChange={this.setTextFont} >
                            {this.state.fontFamilyNames.map((item)=>{
                            return <option style={{fontFamily:item}}>{item}</option>
                            })}
                        </select>
                       </span>

                       <span className="" title="Font Size">
                           &nbsp;&nbsp;<select onChange={this.fontSize} value={this.state.value} style={{ border: '2px solid #ECECEC' }}>
                              {this.state.fontSizes.map((item)=>{
                           return <option>{item}</option>
                              })}
                           </select>

                       </span>
                       </div>

                       <div className='col-12 py-1' style={{textAlign:"left"}}>

                        <span className="px-1">
                        { canvas.getActiveObject().type==="textbox" &&
                            <>
                            <span title="Left" onClick={this.alignObjectLeft}  className={`px-1 ${
                        this.state.canvasObj?.textAlign === "left" ? 'activetext' : null
                    }`}>
                                <img className="" style={{height:"20px",width:"20px"}} src={require('../images/align-left-50.png')} alt="" />
                            </span>
                            <span title="Center" onClick={this.alignObjectCenter}  className={`px-1 ${
                        this.state.canvasObj?.textAlign === "center" ? 'activetext' : null
                    }`}>
                                <img className="" style={{height:"20px",width:"20px"}} src={require('../images/align-center-50.png')} alt="" />
                            </span>
                            <span title="Right" onClick={this.alignObjectRight}  className={`px-1 ${
                        this.state.canvasObj?.textAlign === "right" ? 'activetext' : null
                    }`}>
                                <img className="" style={{height:"20px",width:"20px"}} src={require('../images/align-right-50.png')} alt="" />
                            </span>
                            </>
                        }
                        </span>

                       <span className="px-1">
                           <span title="Send Back" onClick={this.sendBackward} className="px-1">
                               <img className="" style={{height:"20px",width:"20px"}} src={require('../images/send-backward.svg')} alt="" />
                           </span>
                           <span title="Bring Forward" onClick={this.bringForward} className="px-1">
                               <img className="" style={{height:"20px",width:"20px"}} src={require('../images/bring-forward.svg')} alt="" />
                           </span>
                       </span>

                       {/* <span className="px-1">
                           <span title="Group" onClick={this.groupItems} className="px-1">
                               <i className="arrow fa fa-object-group" aria-hidden="true" style={{height:"20px",width:"20px"}}></i>
                           </span>
                           <span title="Ungroup" onClick={this.unGroupItems} className="px-1">
                               <i className="arrow fa fa-object-ungroup" aria-hidden="true" style={{height:"20px",width:"20px"}}></i>
                           </span>
                       </span> */}
                       <span className="px-2">
                       {/* <div>W:<input className="width" style={{ width: "70px", border: "1px solid #c1c1c1" }} type="number" name="width" id="width" onChange={this.setWidth} min="200" max="5000" step="5" /></div>
                       <div>H:<input style={{ width: "70px", border: "1px solid #c1c1c1" }} type="number" name="height" id="height" value={activeObjectHeight} onChange={this.setHeight} /></div> */}
                       {/* <span title="Clone the Item" className="mr-1" onClick={this.clone}><img className="toolbar-icon tNumber" style={{height:"20px",width:"20px"}} src={require('../images/clone.png')} alt="" /></span> */}
                       <span title="Mask the Item" className="colrsec btn-duplicate" onClick={()=>this.props.setMaskingMobile()}> <img className="toolbar-icon tNumber" style={{height:"20px",width:"20px"}} src={require('../images/circle-in-square.png')} alt="" />
                    </span>

                   </span>
                       </div>

                       <div className='col-12 pt-2' style={{textAlign:"left"}}>
                       <span className="px-1">


                       {/* <span className="font-color-container colorpic px-1" onClick={this.pickerOpen} title="Font/Fill Color">
                           <span className="colrsec" onClick={this.pickerOpen}>
                               <div ref={this.textcolorRef} className="primcol textcolpick" />
                               &nbsp;&nbsp;<img className="arrowimg  textcolpick" style={{width:"20px",height:"20px"}} src={require('../images/down-arrow.png')} alt="" onClick={this.pickerOpen} />
                           </span>
                           {this.state.displayColorPicker
                           ? <span style={popover} >
                               <div style={cover} onClick={this.pickerClose} />
                               <SketchPicker color={this.state.textColor} onChangeComplete={this.setColor} />
                           </span>
                           : null
                       }
                       </span> */}




                           <span className="colrsec px-1" title="Rotate">
                               <img id="icon" src={require('../images/rotate.png')} height="20px" width="20px" onClick={this.rotate} style={{ cursor: "pointer" }} alt="" />
                           </span>
                           {!isObjectLocked ? <span className="colrsec px-1" title="Lock">
                               <img id="icon" src={process.env.PUBLIC_URL + '/assets/images/lock.jpg'} height="20px" width="20px" onClick={this.lock} style={{ cursor: "pointer" }} alt="" />
                           </span> : ''}
                           {isObjectLocked ? <span className="colrsec px-1" title="Unlock">
                               <img id="icon" src={process.env.PUBLIC_URL + '/assets/images/unlock.png'} height="20px" width="20px" onClick={this.unlock} style={{ cursor: "pointer" }} alt="" />
                           </span> : ''}
                           { canvas.getActiveObject().type==="textbox" &&
                           <>
                            <span className="px-1" onClick={this.pickerOpen} style={{width:"20px",height:"20px"}} title="Font/Fill Color">
                            <span className="" onClick={this.pickerOpen}>
                                {/* <span ref={this.textcolorRef} className="" style={{border:"0.5px solid black"}}/> */}
                                <img className="  " style={{width:"10px",height:"10px"}} src={require('../images/down-arrow.png')} alt="" onClick={this.pickerOpen} />
                                {
                                this.state.displayColorPicker
                                    ?
                                    <span style={popover2} >
                                        <span style={cover} onClick={this.pickerClose} />
                                        <SketchPicker color={this.state.textColor} onChangeComplete={this.setColor} />
                                    </span>
                                    : null
                                }
                            </span></span>
                           </>
                           }

                       </span>

                       <span className="px-1">
                           { canvas.getActiveObject().type==="textbox" &&
                           <>
                           <span title="Bold" onClick={this.setTextBold} className={`  ${
                        this.state.canvasObj?.fontWeight === 'bold' ? 'activetext' : null
                    }`}>
                               <img className="toolbar-icon tbold" style={{height:"20px",width:"20px"}} src={require('../images/bold.png')} alt="" />
                           </span>
                           <span title="Italic" onClick={this.setTextItalic} className={`px-1  ${
                        this.state.canvasObj?.fontStyle === "italic" ? 'activetext' : null
                    }`}>
                               <img className="toolbar-icon titalic" style={{height:"20px",width:"20px"}} src={require('../images/italic.png')} alt="" />
                           </span>
                           <span title="Underline" onClick={this.setTextUnderline} className={`px-1  ${
                        this.state.canvasObj?.underline === "underline" ? 'activetext' : null
                    }`}>
                               <img className="toolbar-icon tunder" style={{height:"20px",width:"20px"}} src={require('../images/underline.png')} alt="" />
                           </span>
                           <span title="Strike out" onClick={this.setTextStrike}  className={`px-1 ${
                        this.state.canvasObj?.linethrough === "linethrough" ? 'activetext' : null
                    }`}>
                               <img className="toolbar-icon tstrike" style={{height:"20px",width:"20px"}} src={require('../images/strikethrough.png')} alt="" />
                           </span>
                           <span title="Bulleted List" onClick={this.setBulletText}   className={`px-1 ${
                        this.state.canvasObj?.listType === "bullet" ? 'activetext' : null
                    }`}>
                               <img className="toolbar-icon tbullet" style={{height:"20px",width:"20px"}} src={require('../images/list-bullet.png')} alt="" />
                           </span>
                           <span title="Ordered List" onClick={this.setNumberText}  className={`px-1 ${
                        this.state.canvasObj?.listType === "number" ? 'activetext' : null
                    }`}>
                               <img className="toolbar-icon tNumber" style={{height:"20px",width:"20px"}} src={require('../images/orderlist.jpg')} alt="" />
                           </span></>
                            }

                           <span className='px-2'>

                            {canvas.getActiveObject().type==="image" && this.state.isCropping===false? <span title="Crop the Item" className="px-1" onClick={()=>{
                            this.editCrop();
                                this.setState({isCropping:true})}
                            }

                        ><img className="colrsec tNumber" style={{height:"20px",width:"20px"}} src={require('../images/crop.png')} alt="" /></span>:null}
                    {this.state.isCropping?<span className='colrsec btn-duplicate px-1 text-menu' onClick={()=>this.cropDone()}>Done</span>:null}
                    {this.state.isCropping?<span className='colrsec btn-duplicate px-4 text-menu' onClick={()=>this.cropCancel()}>Cancel</span>:null}
                    </span>
                    <span title="Delete" className="btn-delete" onClick={isObjectLocked? null : this.deleteItem}>
                            <>
                                <i className="far fa-trash-alt arrow" style={{color:isObjectLocked ? "grey" : "red"}}></i>
                            </>
                    </span>


                       </span>
                       </div>



                       {/* <div className="overlap-container">
                           <div title="Horizontalflip" onClick={this.horizontalflip} className="horizontalflip">
                               <i className="fas fa-ruler-vertical fa-rotate-270" aria-hidden="true"></i>
                           </div>
                           <div title="Verticalflip" onClick={this.verticalflip} className="verticalflip">
                               <i className="fas fa-ruler-vertical"></i>
                           </div>
                       </div> */}

                       {/* <div title="Effects" className="txteff">
                           <Popup onOpen={this.popupOpen} onClose={this.popupClose} className="popupcontent"
                               trigger={<div className="toolbar-label">Effects</div>}
                               position="bottom center"
                               closeOnDocumentClick
                           >
                               <div className="effects">
                                   <div className="opacity-section">
                                       <div className="toolbar-label opacity">Opacity</div>
                                       <div className="slider-view">
                                           <input
                                               type="range"
                                               className="slider opacityslider"
                                               max="100"
                                               min="0"
                                               step="5"
                                               onChange={this.setOpacity}
                                               value={this.state.opacityval}
                                           />
                                           <div>{this.state.opacityval}%</div>
                                       </div>
                                   </div>

                                   <div className="effsection separator">
                                       <div className="toolbar-label">Outline</div>
                                       <div className="control">
                                           <input type="checkbox" id="switch" onChange={this.outlineCheckBox} checked={this.state.outlinechecked} />
                                           <label htmlFor="switch" onClick={this.outlinetoggle}>Toggle</label>
                                       </div>
                                   </div>
                                   <Collapse isOpen={this.statecollapse} className="strokesection">
                                       <div className="effsection">
                                           <div className="toolbar-label">Color</div>
                                           <div className="font-color-container">
                                               <div className="colrsec" onClick={this.strokepickerOpen}>
                                                   <div ref={this.textstrokecolRef} className="primcol" style={{ background: this.state.backgroundcol }} />
                                                   <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                               </div>
                                           </div>
                                           {this.state.displaystrokeColorPicker
                                               ? <div style={strokepopover}>
                                                   <div style={strokecover} onClick={this.strokepickerClose} />
                                                   <SketchPicker color={this.state.strokeColor} onChangeComplete={this.setStroke} />
                                               </div>
                                               : null
                                           }
                                       </div>
                                       <div className="effsection">
                                           <div className="toolbar-label">Width</div>
                                           <div className="slider-view">
                                               <input
                                                   type="range"
                                                   className="slider widthslider"
                                                   max="5"
                                                   min="1"
                                                   step="1"
                                                   onChange={this.setStrokeval}
                                                   value={this.state.strokeval}
                                               />
                                               <div>{this.state.strokeval}</div>
                                           </div>
                                       </div>
                                   </Collapse>

                                   <div className="effsection separator">
                                       <div className="toolbar-label">Glow</div>
                                       <div className="control">
                                           <input type="checkbox" id="switch1" onChange={this.glowCheckBox} checked={this.state.glowchecked} />
                                           <label htmlFor="switch1" onClick={this.glowtoggle}>Toggle</label>
                                       </div>
                                   </div>
                                   <Collapse isOpen={this.state.glowcollapse} className="glowsection">
                                       <div className="effsection">
                                           <div className="toolbar-label">Color</div>
                                           <div className="font-color-container">
                                               <div className="colrsec" onClick={this.glowpickerOpen}>
                                                   <div ref={this.textglowcolRef} className="primcol" style={{ background: this.state.glowbackgroundcol }} />
                                                   <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                               </div>
                                           </div>
                                           {this.state.displayglowColorPicker
                                               ? <div style={glowpopover}>
                                                   <div style={glowcover} onClick={this.glowpickerClose} />
                                                   <SketchPicker color={this.state.glowColor} onChangeComplete={this.setGlow} />
                                               </div>
                                               : null
                                           }
                                       </div>
                                       <div className="effsection">
                                           <div className="toolbar-label">offsetX</div>
                                           <div className="slider-view">
                                               <input
                                                   type="range"
                                                   className="slider widthslider"
                                                   max="10"
                                                   min="1"
                                                   step="1"
                                                   onChange={this.setglowoffsetX}
                                                   value={this.state.offsetX}
                                               />
                                               <div>{this.state.offsetX}%</div>
                                           </div>
                                       </div>
                                       <div className="effsection">
                                           <div className="toolbar-label">offsetY</div>
                                           <div className="slider-view">
                                               <input
                                                   type="range"
                                                   className="slider widthslider"
                                                   max="10"
                                                   min="1"
                                                   step="1"
                                                   onChange={this.setglowoffsetY}
                                                   value={this.state.offsetY}
                                               />
                                               <div>{this.state.offsetY}%</div>
                                           </div>
                                       </div>
                                       <div className="effsection">
                                           <div className="toolbar-label">Blur</div>
                                           <div className="slider-view">
                                               <input
                                                   type="range"
                                                   className="slider widthslider"
                                                   max="10"
                                                   min="1"
                                                   step="1"
                                                   onChange={this.setglowblur}
                                                   value={this.state.blurval}
                                               />
                                               <div>{this.state.blurval}%</div>
                                           </div>
                                       </div>
                                   </Collapse>
                               </div>
                           </Popup>
                       </div> */}

                       {/* <div title="Effects" className="elementeff">
                           <Popup className="elepopupcontent"
                               trigger={<div className="toolbar-label">Effects</div>}
                               position="bottom center"
                               closeOnDocumentClick
                           >
                               <div className="effects">
                                   <div className="opacity-section">
                                       <div className="toolbar-label opacity">Opacity</div>
                                       <div className="slider-view">
                                           <input
                                               type="range"
                                               className="slider opacityslider"
                                               max="100"
                                               min="0"
                                               step="5"
                                               onChange={this.setOpacity}
                                               value={this.state.opacityval}
                                           />
                                           <div>{this.state.opacityval}%</div>
                                       </div>
                                   </div>

                               </div>
                           </Popup>
                       </div> */}

                       <span title="Effects" className="strokeeff">
                           <Popup className="popupcontent"
                               trigger={<div className="toolbar-label">Stroke</div>}
                               position="bottom center"
                               closeOnDocumentClick>
                               <span>
                                   <div className="effects">
                                       <div className="effsection">
                                           <div className="toolbar-label stroke-label">Stroke Color</div>
                                           <div className="font-color-container">
                                               <div className="colrsec" onClick={this.strokepickerOpen}>
                                                   <div ref={this.textstrokecolRef} className="primcol strokecolpick" />
                                                   <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                               </div>
                                               {
                                                   this.state.displaystrokeColorPicker
                                                       ? <div style={strokepopover} className="strokecolpic">
                                                           <div style={strokecover} onClick={this.strokepickerClose} />
                                                           <SketchPicker color={this.state.strokeColor} onChangeComplete={this.setStroke} />
                                                       </div>
                                                       : null
                                               }
                                           </div>
                                       </div>
                                       <hr />
                                       <div className="effsection">
                                           <div className="toolbar-label stroke-label">Stroke Width</div>
                                           <div className="slider-view">
                                               <input
                                                   type="range"
                                                   className="slider strokeslider"
                                                   max="10"
                                                   min="1"
                                                   step="1"
                                                   onChange={this.setStrokeval}
                                                   value={this.state.strokeval}
                                               />
                                               <div>{this.state.strokeval}%</div>
                                           </div>
                                       </div>
                                   </div>
                               </span>
                           </Popup>
                       </span>
                   </div>

          </Container>
            }
    </div>
        );
    }
}

export default Toolbar;
