export const HOST_SITE = 'http://lamcraft-front.cns72.com/'
export const LOGO_URL = 'https://cnsdrive.com/lamcraft/'
export const DPI = 96
export const DPI_THRESHOLD = 150
export const IMAGE_MAX_WIDTH = 3000
export const DEFAULT_FONT_PROPS = {
    font:'Montserrat',
    heading_size: 16,
    title_size: 14,
    text_size: 10,
}

export const productTypes = {
    "4up": {
        bookmarkComment: '2.75" w x 8.5" h (4 on a Page)',
        value:'4up',
        name:'Bookmark (4-Up)',
        canvaswidth: "2.75in",
        canvasheight: "8.5in"
    },
    "3up": {
        bookmarkComment: '2.83"w x 11"h (3 on a Page)',
        value:'3up',
        name:'Bookmark (3-Up)',
        canvaswidth: "2.83in",
        canvasheight: "11in",
    },
    "2up": {
        bookmarkComment: '4.25" w x 11" h (2 on a Page)',
        value:'2up',
        name:'Bookmark (2-Up)',
        canvaswidth: "4.25in",
        canvasheight: "11in",
    },
    "8up": {
        bookmarkComment: '2.5" w x 4.25" h (8 on a Page)',
        value:'8up',
        name:'Prayer Card (8-Up)',
        canvaswidth: "2.5in",
        canvasheight: "4.25in",
    }
}

export const  PROPS_TO_INCLUDE_IN_CANVAS = ['id','name','metaType',
"scaleX",
"scaleY", 
"objectCaching",
"isLocked",
"lockMovementX", 
"lockMovementY", 
"lockScalingX", 
"lockScalingY", 
"lockRotation",
"isWrapping",
"listType",
"listBullet",
"listStyle",
"listCounter",
"listNull",
"fontStyle",
"family",
"fontFamily",
"setActiveStyle",
"fontSize",
"fontWeight",
"lineHeight",
"originX",
"originY",
"breakWords",
"_renderTextLine",
"height",
"width",
"editable",
"includeDefaultValues",
"textLines",
"_charBounds",
"_eventListeners",
"_textLines",
"applyFilters",
"filters",
"_dimensionAffectingProps",
"_lineHeights",
"_lineWidths",
"_text",
"_unwrappedTextLines",
"_renderChars",
"_renderTextLine",
"isEndOfWrapping",
"textAlign",
"zoomX",
"zoomY",
"oCoords",
"isRendering",
"charSpacing",
"textAlign",
"styles",
"path",
"pathStartOffset",
"pathSide",
"pathAlign",

]

// "fontFamily",
// "width",
// "textAlign",
// "textLines",
// "zoomX",
// "zoomY",
// "type",
// "cacheHeight",
// "cacheTranslationX",
// "cacheTranslationY",
// "cacheWidth",
// "aspectRation",
// "isRendering",
// "height",
// "lineCoords",
// "dirty",
// "_lineHeights",
// "_lineWidths",
// "strokeWidth",
// "strokeLineJoin",
// "subTargetCheck",
// "transparentCorners",
// "cornerColor",
// "cornerStrokeColor",
// "borderColor",
// "cornerSize",
// "padding",
// "breakWords",
// "top"




export const templates = {
    "meadowsnv": {
        'title': 'meadowsnv',
        'default': true,
        'front': process.env.PUBLIC_URL + '/assets/images/front.jpg',
        'back':process.env.PUBLIC_URL + '/assets/images/back.jpg',
        'type':'4up'
    },
    "meadownv": {
        'title': 'meadownv',
        'default': false,
        'front': process.env.PUBLIC_URL + '/assets/images/4upMP_MeadowNV-Front.jpg',
        'back':process.env.PUBLIC_URL + '/assets/images/4upMP_Meadow-Back.jpg',
        'type':'4up'

    },
    "bluebonnetsnv":{
        'title': 'bluebonnetsnv',
        'default': true,
        'front': process.env.PUBLIC_URL + '/assets/images/4upMP_BluebonnetsNV-Front.jpg',
        'back':process.env.PUBLIC_URL + '/assets/images/4upMP_Bluebonnets-Back.jpg',
        'type':'4up'
    },
    "rose":{
        'title': 'rose',
        'default': false,
        'front': process.env.PUBLIC_URL + '/assets/images/244570-11_RoseRoseFront.jpg',
        'back':process.env.PUBLIC_URL + '/assets/images/244570-11_RoseRoseBack.jpg',
        'type':'2up'

    },

    "summerfield":{
        'title': 'summerfield',
        'default': false,
        'front': process.env.PUBLIC_URL + '/assets/images/2445108-11_SummerFieldFront.jpg',
        'back':process.env.PUBLIC_URL + '/assets/images/2445108-11_SummerFieldBack.jpg',
        'type':'2up'

    },
    "antiqueborder":{
        'title': 'antiqueborder',
        'default': false,
        'front': process.env.PUBLIC_URL + '/assets/images/2445109-37_AntiqueBorderFront.jpg',
        'back':process.env.PUBLIC_URL + '/assets/images/2445109-37_AntiqueBorderBack.jpg',
        'type':'2up'

    },
    "endless-2up":{
        'title': 'endless-2up',
        'default': false,
        'front': process.env.PUBLIC_URL + '/assets/images/2445333-41_EndlessFront.jpg',
        'back':process.env.PUBLIC_URL + '/assets/images/2445333-41_EndlessBack.jpg',
        'type':'2up'

    },
    "seaoats":{
        'title': 'seaoats',
        'default': false,
        'front': process.env.PUBLIC_URL + '/assets/images/231758-11_SeaOatsFront.jpg',
        'back':process.env.PUBLIC_URL + '/assets/images/231758-11_SeaOatsBack.jpg',
        'type':'3up'
    },
    "butterfly":{
        'title': 'butterfly',
        'default': false,
        'front': process.env.PUBLIC_URL + '/assets/images/231784-32_ButterflyFront.jpg',
        'back':process.env.PUBLIC_URL + '/assets/images/231784-32_ButterflyBack.jpg',
        'type':'3up'

    },
    "homestead":{
        'title': 'homestead',
        'default': false,
        'front': process.env.PUBLIC_URL + '/assets/images/231790-11_HomesteadFront.jpg',
        'back':process.env.PUBLIC_URL + '/assets/images/231790-11_HomesteadBack.jpg',
        'type':'3up'
    },
    "endless-3up":{
        'title': 'endless-3up',
        'default': false,
        'front': process.env.PUBLIC_URL + '/assets/images/2317333-41_EndlessFront.jpg',
        'back':process.env.PUBLIC_URL + '/assets/images/2317333-41_EndlessBack.jpg',
        'type':'3up'
    },
    "rosebud":{
        'title': 'rosebud',
        'default': false,
        'front': process.env.PUBLIC_URL + '/assets/images/210693-35_RosebudFront.jpg',
        'back':process.env.PUBLIC_URL + '/assets/images/210693-35_RosebudBack.jpg',
        'type':'4up'
    },
    "sandybeach":{
        'title': 'sandybeach',
        'default': false,
        'front': process.env.PUBLIC_URL + '/assets/images/2106157-11_SandyBeachFront.jpg',
        'back':process.env.PUBLIC_URL + '/assets/images/2106157-11_SandyBeachBack.jpg',
        'type':'4up'
    },
    "rosegarden":{
        'title': 'rosegarden',
        'default': false,
        'f': process.env.PUBLIC_URL + '/assets/images/2106346-11_RoseGardenFront.jpg',
        'front': process.env.PUBLIC_URL + '/assets/images/2106346-84_RoseGarden-Front.jpg',
        'back':process.env.PUBLIC_URL + '/assets/images/2106346-11_RoseGardenBack.jpg',
        'type':'4up',
    },
    "celticcross":{
        'title': 'celticcross',
        'default': false,
        'front': process.env.PUBLIC_URL + '/assets/images/3520119_CelticCross-Front.jpg',
        'back':process.env.PUBLIC_URL + '/assets/images/8-upCards-Back.jpg',
        'type':'8up'
    },
}

export const VERSES = [

    {
        title: `23rd Psalm`,
        data: `The Lord is my shepherd;\nI shall not want. He maketh me\nto lie down in green pastures;\nHe leadeth me beside the still waters.\nHe restoreth my soul; He leadeth me\nin the paths of righteousness\nfor His name’s sake.\nYea, though I walk through\nthe valleyof the shadow of death,\nI will fear no evil; for Thou art with me;\nthy rod and thy staff they comfort me\nThou preparest a table before me\nin the presence of mine enemies:\nThou anointest my head with oil;\nmy cup runneth over. Surely goodness\nand mercy shall follow me all\nthe days of my life: and I shall dwell\nin the house of the Lord forever.`    },
    {
        title: `Afterglow`,
        data:  `I’d like the memory of me\nto be a happy one.\nI’d like to leave\nan afterglow of smiles\nwhen day is done.\nI’d like to leave an echo…\nWhispering softly down\nthe ways of happy times and\nlaughing times and\nbright and sunny days.\nI’d like the tears of those\nwho grieve to dry before the sun\nof happy memories\nthat I leave behind\nwhen day is done.`
    },
    {
        title: `Butterfly Poem`,
        data: `A butterfly perched on my cheek,\nBrought hope that it would stay.\nBut with a gentle, breathless kiss,\nToo soon it flew away.`
    },
    {
        title: `Ecclesiastes 3:1-8`,
        data: `There is a season for everything,\na time for every occupation under heaven.\nA time for giving birth,\nA time for dying.\nA time for planting,\nA time for uprooting what has been planted.\nA time for killing,\nA time for healing;\nA time for knocking down,\nA time for building.\nA time for tears,\nA time for laughter;\nA time for mourning,\nA time for dancing.\nA time for throwing stones away,\nA time for gathering them up;\nA time for embracing,\nA time to refrain from embracing.\nA time for searching,\nA time for losing;\nA time for keeping.\nA time for throwing away.\nA time for tearing,\nA time for sewing;\nA time for keeping silent,\nA time for speaking.\nA time for loving,\nA time for hating;\nA time for war,\nA time for peace.`
    },
    {
        title: `God’s Garden`,
        data: `God looked around his garden,\nand found an empty space.\nHe then looked down upon the earth,\nand saw your tired face.\nHe put his arms around you,\nand lifted you to rest;\nGod’s garden must be beautiful…\nHe only takes the best.\nHe knew that you were suffering,\nHe knew you were in pain;\nHe knew that you would never\nbe well on earth again.\nHe saw the road was getting rough,\nand the hills were hard to climb.\nSo he closed your weary eyelids,\nand whispered, “Peace be thine.”\nIt broke our hearts to lose you,\nbut you didn’t go alone,\nfor a part of us went with you,\nthe day God called you home.`
    },
    {
        title: `I’m Free`,
        data: `Don’t grieve for me, for now I’m free\nI’m following the path God laid for me.\nI took His hand when I heard Him call\nI turned my back and left it all.\n\nI could not stay another day\nTo laugh, to love, to work or play.\nTasks left undone must stay that way.\nI found that place at the close of day.\n\nIf my passing has left a void\nThen fill it with remembered joy.\nA friendship shared, a laugh, a kiss.\nAh yes, these things, I too, will miss.\n\nBe not burdened with times of sorrow.\nI wish you the sunshine of tomorrow.\nMy life’s been full, I savored much\nGood friends, good times, a loved one’s touch.\nPerhaps my time seemed all too brief;\nDon’t lengthen it now with undue grief.\nLift up your heart and share with me\nGod wanted me now, He set me free.\n\nAuthor unknown`
    },
    {
        title: `John 8:12`,
        data: `I am the Light of the World.\nWhoever follows Me\nwill not walk in darkness,\nbut will have the light of life.`
    },
    {
        title: `John 14:1-3`,
        data: `Let not your heart be troubled;\nbelieve in God, believe also in me.\nIn my Father’s house are many mansions;\nif it were not so, I would have told you.\nI go to prepare a place for you.\nAnd if I go and prepare a place for you\nI will come again, and receive you\nunto myself; that where I am\nthere you may be also.`
    },
    {
        title: `Miss Me But Let Me Go`,
        data: `When I come to the end of the road,\nand the sun has set for me,\nDo not shed tears my family and friends,\nwhy cry for a soul set free?\n\nMiss me a little – but not too long,\nand not with your head bowed low,\nremember the love that we once shared.\nMiss me but let me go.\n\nFor this is a journey we all must take,\nand each must go alone.\nIt’s all a part of the Master’s plan,\na step on the road to home.\n\nWhen you are lonely and sick of heart,\ngo to the friends we know.\nBury your sorrows in doing good deeds.\nMiss me but let me go.`
    },
    {
        title: `We Will Always Remember`,
        data: ``
    }

]